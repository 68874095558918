import React from 'react';
import styled from 'styled-components';
import { Container, Subtitle, Title } from '@/Components/ConfigModal/ConfigModal.styles';
import { KEYBOARD_TYPE, KeyboardTypeLabels } from '@/Models/Constants';
import useUserSessionStore from '@/Models/useUserSessionStore';

interface RadioOptionProps {
  checked: boolean;
}

export const ControlGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.8em;
  width: 100%;
  justify-content: center;
  align-items: stretch;
`;

const RadioOption = styled.label<RadioOptionProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 16px;
  background: ${props => (props.checked ? '#007bffe7' : '#2a2a2a')};
  border: 1px solid ${props => (props.checked ? '#007bff' : '#444')};
  border-radius: 6px;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => (props.checked ? 'rgba(0, 123, 255, 0.15)' : '#3a3a3a')};
  }

  input {
    display: none;
  }

  div {
    color: ${props => (props.checked ? '#fff' : 'rgba(255, 255, 255, 0.5)')};
  }
`;

const KeyboardTypeSelector: React.FC = () => {
  // const { config } = useGestureKeyboardStore();

  const { setKeyboardType, getKeyboardType } = useUserSessionStore();
  const currentType = getKeyboardType();

  const handleKeyboardTypeChange = (type: KEYBOARD_TYPE) => {
    setKeyboardType(type);
  };

  return (
    <Container>
      <Title>Tipo de Teclado</Title>
      <ControlGroup>
        {Object.values(KEYBOARD_TYPE).map(type => (
          <RadioOption key={type} checked={currentType === type}>
            <input type="radio" name="KEYBOARD_TYPE" value={type} checked={currentType === type} onChange={() => handleKeyboardTypeChange(type)} />
            <Subtitle>{KeyboardTypeLabels[type]}</Subtitle>
          </RadioOption>
        ))}
      </ControlGroup>
    </Container>
  );
};

export default KeyboardTypeSelector;
