import React from 'react';
import styled from 'styled-components';
import { EYE_GESTURE_EVENT } from '@/Models/Constants';
import { SliderContainer, SliderLabel } from '@/Components/ConfigModal/ConfigModal.styles';
import useUserSessionStore from '@/Models/useUserSessionStore';

const SliderTrack = styled.div`
  position: absolute;
  width: 100%;
  height: 8px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  outline: 1px solid rgba(0, 123, 255, 0.3);
  top: 50%;
  transform: translateY(-50%);
`;

const Slider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: transparent;
  position: absolute;
  z-index: 2;
  margin: 0;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    background: #007bff;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background 0.2s;

    &:hover {
      background: #0056b3;
    }

    &:active {
      background: #004494;
      transform: scale(1.1);
    }
  }

  &::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: #007bff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background 0.2s;

    &:hover {
      background: #0056b3;
    }

    &:active {
      background: #004494;
      transform: scale(1.1);
    }
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: linear-gradient(to right, rgba(0, 123, 255, 0.3) 0%, rgba(0, 123, 255, 0.3) var(--progress), rgba(0, 0, 0, 0.1) var(--progress), rgba(0, 0, 0, 0.1) 100%);
    border-radius: 4px;
    border: none;
  }

  &::-moz-range-track {
    width: 100%;
    height: 8px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: none;
  }

  &:focus {
    outline: none;
  }
`;

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 8px 0;
  height: 40px;
`;

interface ReactionSliderProps {
  gesture: EYE_GESTURE_EVENT;
}

const ReactionSlider: React.FC<ReactionSliderProps> = ({ gesture }) => {
  const gestureDetection = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.gestureDetection);
  const setGestureDetectionSettings = useUserSessionStore(state => state.setGestureDetectionSettings);
  const reactionTime = gestureDetection[gesture]?.reactionTime || 0;

  const handleReactionTimeChange = (value: number) => {
    setGestureDetectionSettings(gesture, {
      ...gestureDetection[gesture],
      reactionTime: value,
    });
  };

  React.useEffect(() => {
    const slider = document.querySelector('input[type="range"]') as HTMLInputElement;
    if (slider) {
      const progress = ((reactionTime - 0.1) / (1.5 - 0.1)) * 100;
      slider.style.setProperty('--progress', `${progress}%`);
    }
  }, [reactionTime]);

  return (
    <SliderContainer>
      <SliderLabel>
        <span>Confirmación de gesto</span>
        <span>{`${reactionTime}s`}</span>
      </SliderLabel>
      <SliderWrapper>
        <SliderTrack />
        <Slider type="range" min="0.1" max="1.5" step="0.2" value={reactionTime} onChange={e => handleReactionTimeChange(Number(e.target.value))} />
      </SliderWrapper>
    </SliderContainer>
  );
};

export default ReactionSlider;
