import React from 'react';
import styled, { keyframes } from 'styled-components';
import useGestureKeyboardStore from '@/Models/useGestureKeyboardStore';
import useUserSessionStore from '@/Models/useUserSessionStore';

const blink = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const StyledCursor = styled.div<{ $withSpace: boolean; $fontSize: number }>`
  display: inline-block;
  animation: ${blink} 0.7s infinite;
  border-bottom: 5px solid white;
  margin-left: ${props => (props.$withSpace ? '0.25em' : '0')};
  font-size: ${props => props.$fontSize}px;
`;

const Cursor = () => {
  const {
    user: {
      settings: { selectedKeyboardSettings },
    },
  } = useUserSessionStore();

  const { phrase } = useGestureKeyboardStore();
  const isVisible = phrase !== '';
  const withSpace = phrase.length !== 0 && phrase[phrase.length - 1] !== ' ';

  if (!isVisible) return null;

  return (
    <StyledCursor $withSpace={withSpace} $fontSize={selectedKeyboardSettings?.phraseFontSize}>
      _
    </StyledCursor>
  );
};

export default Cursor;
