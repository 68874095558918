import styled from 'styled-components';
import { device } from '@/Layout/breakpoints';

// Componente base para los botones
export const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 1em 2em;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1em;
  transition: all 0.3s ease;
  border-radius: 8px;
  outline: 2px solid white;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
  }

  ${device.mobileAll} {
    outline: none;
    outline: 1px solid red;
    span {
      display: none;
    }
  }

  /* Mobile portrait */
  ${device.mobilePortrait} {
    padding: 0.8em 1.6em;
    font-size: 0.9em;
  }

  /* Mobile landscape */
  ${device.mobileLandscape} {
    padding: 0.6em 1.2em;
    font-size: 0.85em;
    /* outline: 2px solid green; */
  }

  /* Tablet portrait */
  ${device.tabletPortrait} {
    padding: 1em 2em;
    font-size: 1em;
  }

  /* Tablet landscape */
  ${device.tabletLandscape} {
    padding: 0.8em 1.8em;
    font-size: 0.95em;
  }
`;
