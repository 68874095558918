import styled from 'styled-components';

export const Section = styled.div<{ columns?: number }>`
  margin-bottom: 20px;
  padding: 26px 13px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  height: auto;
  display: grid;
  grid-template-columns: ${props => `repeat(${props.columns || 1}, 1fr)`};
  gap: 13px;
  &:last-child {
    /* grid-column: 1 / -1; */
  }
  max-width: 100%;
`;

// export const SliderGroup = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   gap: 16px;
// `;

export const SliderGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #2a2a2a;
  padding: 12px 20px;
  border-radius: 6px;
  border: 1px solid #444;
  transition: all 0.2s ease;

  &:hover {
    background: #3a3a3a;
  }
`;

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 25vw;
  padding: 16px 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  outline: 1px solid rgba(255, 0, 208, 0.2);
`;

export const SliderLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.5rem;

  span:last-child {
    min-width: 40px;
    text-align: right;
  }
`;

export const Slider = styled.input`
  width: 100%;
  height: 4px;
  background: #2a2a2a;
  border-radius: 2px;
  outline: none;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: #007bff;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background: #0056b3;
    }
  }
`;

export const sensitivityLabels = {
  1: 'Baja',
  2: 'Media',
  3: 'Alta',
};

interface StyledModalContentProps {
  $isLandscape: boolean;
}

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
  overflow: hidden;
  /* padding: 20px; */

  @media (max-width: 768px) {
    padding: 0;
    align-items: flex-start;
  }
`;

// Primero definimos las interfaces para los props de los styled components
export interface StyledModalProps {
  $isLandscape: boolean;
}

// Actualizamos los styled components con sus tipos
export const Modal = styled.div<StyledModalProps>`
  background-color: #1a1a1a;
  border-radius: 8px;
  width: ${({ $isLandscape }) => ($isLandscape ? '95%' : '100%')};
  /* max-width: ${({ $isLandscape }) => ($isLandscape ? '1400px' : '800px')}; */
  color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  height: ${({ $isLandscape }) => ($isLandscape ? '90vh' : '100vh')};
  /* overflow: hidden; */
  /* outline: 1px solid magenta; */
  position: relative;
  @media (max-width: 768px) {
    border-radius: 0;
    height: 100vh;
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  flex-shrink: 0;

  h2 {
    margin: 0;
    font-size: 2rem;
    font-weight: 500;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: rgba(180, 70, 70, 0.7);
  font-size: 2rem;
  line-height: 1;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
  /* padding: .1rem .3rem;  */
  min-width: 3.1rem;
  min-height: 3.1rem;
  outline: 1px solid rgba(180, 70, 70, 0.7);
  &:hover {
    color: white;
    background-color: rgba(255, 0, 0, 0.4);
  }
`;

export const ModalContent = styled.div<StyledModalContentProps>`
  /* flex: 1; */
  overflow-y: scroll;
  /* overflow-x: hidden; */
  /* padding: 24px; */
  /* display: flex; */
  /* flex-direction: column; */
  height: auto;

  /* outline: 1px solid blue; */

  margin: 26px 13px;
`;

export const SectionsWrapper = styled.div`
  /* flex: 1; */
  /* height: auto; */
  outline: 2px solid green;
  max-width: 100%;
  padding: 0 9px;

  /* display: flex; */
  /* flex-direction: column; */
  /* gap: 20px; */
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 8px;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-track {
    background: #1a1a1a;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #444;
    border-radius: 4px;

    &:hover {
      background: #555;
    }
  }

  scrollbar-width: thin;
  scrollbar-color: #444 #1a1a1a;
`;

export const Title = styled.div`
  margin: 0 0 13px 0;
  color: rgba(255, 255, 255, 1);
  font-size: 1.8rem;
`;

export const Subtitle = styled.div`
  /* margin: 0 0 13px 0; */
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.5rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  /* outline: 1px solid blue; */
  /* padding: 2rem 0 .5rem 0; */
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(0, 123, 255, 0.2);
  margin-bottom: 26px;
  transition: all 0.2s ease;

  &:hover {
    border-color: rgba(0, 123, 255, 0.3);
  }
`;
