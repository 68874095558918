import styled from 'styled-components';

type ButtonSize = 'small' | 'large';

interface PauseButtonProps {
  $size: ButtonSize;
}

const sizeMap = {
  small: {
    width: '40px',
    height: '40px',
    fontSize: '20px',
    lineHeight: '20px',
    padding: '12px',
  },
  large: {
    width: '40px',
    height: '40px',
    fontSize: '25px',
    lineHeight: '25px',
    padding: '15px',
  },
};

export const StyledPauseButton = styled.button<PauseButtonProps>`
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
  width: ${props => sizeMap[props.$size].width};
  height: ${props => sizeMap[props.$size].height};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.15s ease;
  color: white;
  font-size: ${props => sizeMap[props.$size].fontSize};
  line-height: ${props => sizeMap[props.$size].lineHeight};
  padding: ${props => sizeMap[props.$size].padding};
  outline: 0.125rem solid rgba(255, 255, 255, 0.25);

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
    outline: 4px solid rgba(255, 255, 255, 1);
  }
`;
