/* eslint-disable no-debugger */
import axios from 'axios';
import { defaultUserSettings, IUser, IUserSettings } from '@/Models/Types';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { KEYBOARD_TYPE } from '@/Models/Constants';

export const API_URL = process.env.REACT_APP_API;

const axiosInstance = axios.create({
  baseURL: `http://${API_URL}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const getAxiosInstance = () => {
  return axiosInstance;
};

export const getAuthToken = () => {
  const userToken = localStorage.getItem('user');
  if (userToken) return { authToken: userToken };
  return { authToken: null };
};

// export interface IUserSettings {
//   version: number;
//   selectedKeyboard: KEYBOARD_TYPE;
//   selectedKeyboardSettings: IKeyboardSettings;
//   [KEYBOARD_TYPE.VERTICAL_SPLIT_SELECTION]: IKeyboardSettings;
//   [KEYBOARD_TYPE.HORIZONTAL_SPLIT_SELECTION]: IKeyboardSettings;
//   [KEYBOARD_TYPE.HORIZONTAL_TRACKING_MOUSE]: IKeyboardSettings;
// }

// export interface IKeyboardSettings {
//   keyboardSpeakGesture: EYE_GESTURE_EVENT;
//   keyFontSize: number;
//   phraseFontSize: number;
//   eyeSelection?: IEyeSelection;
// }

const handleSettings = (settings: IUserSettings): IUserSettings => {
  const keyboardInputMode = settings?.keyboardInputMode;
  const keyboardDistribution = settings?.keyboardDistribution;

  const selectedKeyboard =
    keyboardInputMode === 'POINTER'
      ? KEYBOARD_TYPE.HORIZONTAL_TRACKING_MOUSE
      : keyboardDistribution === 'HORIZONTAL'
        ? KEYBOARD_TYPE.HORIZONTAL_SPLIT_SELECTION
        : KEYBOARD_TYPE.VERTICAL_SPLIT_SELECTION;
  if (!settings?.version) {
    console.log('[STATUS] settings', settings);

    const { navigation, id } = settings;
    return {
      ...defaultUserSettings,
      id,
      navigation,
      selectedKeyboard,
      selectedKeyboardSettings: defaultUserSettings[selectedKeyboard],
    };
  }
  console.log('[STATUS] settings', settings);
  return settings;
};

const bootstrapUser = ({ authToken, settings: serverSettings, birthdate, email, id, name, role }: IUser) => {
  const { setUserSessionState } = useUserSessionStore.getState();

  const settings: IUserSettings = handleSettings(serverSettings);

  console.log('SETTINGS', settings);
  setUserSessionState({
    authToken,
    settings,
    birthdate,
    email,
    id,
    name,
    role,
  });
};

export const login = async (email: string, password: string) => {
  const { data } = await axiosInstance.post('/user/login', {
    email,
    password,
  });

  bootstrapUser(data);
  return data;
};
