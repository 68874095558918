import styled from 'styled-components';
import { device } from '@/Layout/breakpoints';

export const HomeContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  overflow: hidden;
`;

export const HomeMain = styled.div`
  flex: 1;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

export const HomeHeader = styled.header`
  width: 100vw;

  /* Mobile styles */
  ${device.mobilePortrait} {
    height: clamp(120px, 20vh, 160px);
  }

  /* Mobile landscape specific */
  ${device.mobileLandscape} {
    height: clamp(60px, 15vh, 90px);
  }

  /* Tablet portrait specific */
  ${device.tabletPortrait} {
    height: clamp(100px, 18vh, 140px);
  }

  /* Tablet landscape specific */
  ${device.tabletLandscape} {
    height: clamp(80px, 15vh, 120px);
  }

  /* Desktop styles */
  ${device.desktop} {
    height: clamp(70px, 20vh, 240px);
  }

  ${device.desktopLandscape} {
    height: clamp(80px, 25vh, 256px);
  }
`;

export const HomeBody = styled.main`
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  outline: 5px solid orange;

  /* Mobile styles */
  ${device.mobilePortrait} {
    flex-direction: column;
  }

  /* Mobile landscape specific */
  ${device.mobileLandscape} {
    flex-direction: row;
    padding-left: 1vw;
  }

  /* Tablet portrait specific */
  ${device.tabletPortrait} {
    flex-direction: column;
    padding-top: 2vh;
  }

  /* Tablet landscape specific */
  ${device.tabletLandscape} {
    flex-direction: row;
    padding-left: 2vw;
  }

  /* Orientation styles */
  ${device.portrait} {
    flex-direction: column;
    padding-top: 2vh;
  }

  ${device.landscape} {
    flex-direction: row;
    padding-left: 2vw;
  }

  & > * {
    max-width: 100%;
    max-height: 100%;
    outline: 2px dashed purple;
  }
`;

export const HomeButtons = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  /* outline: 2px solid yellow; */

  /* Base styles */
  flex-direction: row;
  justify-content: space-between;
  /* gap: 10px; */
  /* padding: 10px; */

  /* Mobile portrait */
  ${device.mobilePortrait} {
    flex-direction: row;
    /* background-color: rgba(255, 0, 0, 0.1); */
    /* padding: 8px; */
    /* gap: 8px; */
  }

  /* Mobile landscape */
  ${device.mobileLandscape} {
    flex-direction: row;
    justify-content: flex-start;
    /* background-color: rgba(128, 128, 0, 0.1); */
    /* padding: 5px 10px; */
    /* gap: 15px; */
  }

  /* Tablet portrait */
  ${device.tabletPortrait} {
    flex-direction: row;
    justify-content: space-between;
  }

  /* Tablet landscape */
  ${device.tabletLandscape} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const DetectorColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${device.mobilePortrait} {
    flex: 2;
    background-color: rgba(0, 0, 255, 0.1);
  }

  ${device.mobileLandscape} {
    /* flex:auto; */
    margin-right: 15px;
    margin-left: 15px;
    background-color: rgba(128, 0, 128, 0.1);
  }
`;

export const ActionsColumn = styled.div`
  display: flex;
  /* gap: 10px; */
  width: auto;
  height: 100%;

  ${device.mobilePortrait} {
    flex: 3;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    /* gap: 8px; */
    background-color: rgba(0, 255, 0, 0.3);
    /* border-radius: 8px; */
    /* padding: 8px; */
  }

  ${device.mobileLandscape} {
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* gap: 15px; */
    /* padding: 5px; */
    background-color: rgba(200, 200, 0, 0.3);
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
  min-width: 10px;
  outline: 1px solid red;

  ${device.mobilePortrait} {
    justify-content: space-evenly;
    width: 100%;
    /* gap: 8px; */
  }

  ${device.mobileLandscape} {
    flex: 1;
    justify-content: center;
    /* gap: 15px; */
    height: 50%;

    /* &:first-child {
      justify-content: flex-end;
    }
    
    &:last-child {
      justify-content: flex-start;
    } */
  }

  ${device.tabletPortrait} {
    gap: 15px;
  }

  ${device.tabletLandscape} {
    gap: 12px;
  }
`;

export const DetectionContainer = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ConfigButton = styled.button``;
