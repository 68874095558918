import styled from 'styled-components';
import { BaseButton } from '../Shared/ButtonStyles';
import { device } from '@/Layout/breakpoints';

export const LogoutButtonStyled = styled(BaseButton)`
  /* Mobile portrait */
  ${device.mobilePortrait} {
    padding: 1em;
    aspect-ratio: 1;
    justify-content: center;

    span {
      display: none;
    }

    svg {
      margin: 0;
    }
  }

  /* Mobile landscape */
  ${device.mobileLandscape} {
    padding: 0.8em;
    font-size: 0.85em;
  }

  /* Tablet portrait */
  ${device.tabletPortrait} {
    padding: 1em 2em;
    font-size: 1em;
  }

  /* Tablet landscape */
  ${device.tabletLandscape} {
    padding: 0.9em 1.8em;
    font-size: 0.95em;
  }
`;
