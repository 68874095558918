import { useEffect, useRef, useState } from 'react';
import useDetectionStore from '@/Models/useDetectionStore';
import { Category } from '@mediapipe/tasks-vision';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { EYE_GESTURE_EVENT, EyeGestures } from '@/Models/Constants';
import { IEyeDetectionSource, TGesstureScores, TScoreTrackingState } from '@/Models/Types';

const EmptyScoreTrackingState: TScoreTrackingState = {
  historic: [],
  max: {
    [EYE_GESTURE_EVENT.LOOK_UP]: 0,
    [EYE_GESTURE_EVENT.LOOK_DOWN]: 0,
    [EYE_GESTURE_EVENT.LOOK_LEFT]: 0,
    [EYE_GESTURE_EVENT.LOOK_RIGHT]: 0,
    [EYE_GESTURE_EVENT.BLINK]: 0,
    timestamp: '',
  },
  normalized: {
    [EYE_GESTURE_EVENT.LOOK_UP]: 0,
    [EYE_GESTURE_EVENT.LOOK_DOWN]: 0,
    [EYE_GESTURE_EVENT.LOOK_LEFT]: 0,
    [EYE_GESTURE_EVENT.LOOK_RIGHT]: 0,
    [EYE_GESTURE_EVENT.BLINK]: 0,
    timestamp: '',
  },
  last: {
    [EYE_GESTURE_EVENT.LOOK_UP]: 0,
    [EYE_GESTURE_EVENT.LOOK_DOWN]: 0,
    [EYE_GESTURE_EVENT.LOOK_LEFT]: 0,
    [EYE_GESTURE_EVENT.LOOK_RIGHT]: 0,
    [EYE_GESTURE_EVENT.BLINK]: 0,
    timestamp: '',
  },
  idle: {
    [EYE_GESTURE_EVENT.LOOK_UP]: false,
    [EYE_GESTURE_EVENT.LOOK_DOWN]: false,
    [EYE_GESTURE_EVENT.LOOK_LEFT]: false,
    [EYE_GESTURE_EVENT.LOOK_RIGHT]: false,
    [EYE_GESTURE_EVENT.BLINK]: false,
  },
  active: {
    [EYE_GESTURE_EVENT.LOOK_UP]: false,
    [EYE_GESTURE_EVENT.LOOK_DOWN]: false,
    [EYE_GESTURE_EVENT.LOOK_LEFT]: false,
    [EYE_GESTURE_EVENT.LOOK_RIGHT]: false,
    [EYE_GESTURE_EVENT.BLINK]: false,
  },
  confirmed: {
    [EYE_GESTURE_EVENT.LOOK_UP]: false,
    [EYE_GESTURE_EVENT.LOOK_DOWN]: false,
    [EYE_GESTURE_EVENT.LOOK_LEFT]: false,
    [EYE_GESTURE_EVENT.LOOK_RIGHT]: false,
    [EYE_GESTURE_EVENT.BLINK]: false,
  },
};

const getNewScores = (categories: Category[], { leftEye, rightEye }: IEyeDetectionSource) => {
  const gestures: { [key: string]: number } = {};
  categories.map((shape: Category) => {
    const { displayName, categoryName, score } = shape;
    const id = (displayName || categoryName) as EyeGestures;
    if (Object.values(EyeGestures).includes(id)) {
      gestures[id] = score;
    }
  });

  const { eyeLookUpLeft, eyeLookUpRight, eyeLookDownLeft, eyeLookDownRight, eyeLookOutLeft, eyeLookOutRight, eyeBlinkLeft, eyeBlinkRight, eyeLookInLeft, eyeLookInRight } = gestures;

  // Helper function to compute scores based on eye selection
  const computeScore = (eye: string = '', leftScore: number, rightScore: number): number => {
    // console.log('leftEye', leftEye, 'rightEye', rightEye);
    if (leftEye && rightEye) {
      // console.log('leftEye', leftScore, 'rightEye', rightScore);
      return Math.max(leftScore, rightScore);
    } else if (leftEye) {
      if (eye === EYE_GESTURE_EVENT.LOOK_LEFT || eye === EYE_GESTURE_EVENT.LOOK_RIGHT) console.log(eye, 'leftEye', leftScore);
      return leftScore;
    } else if (rightEye) {
      if (eye === EYE_GESTURE_EVENT.LOOK_RIGHT || eye === EYE_GESTURE_EVENT.LOOK_LEFT) console.log(eye, 'rightEye', rightScore);
      return rightScore;
    }
    return 0; // Return 0 if no eyes are selected (shouldn't happen in normal usage)
  };

  // console.log('computeScore', eyeLookUpLeft, eyeLookUpRight, eyeLookDownLeft, eyeLookDownRight, eyeLookOutLeft, eyeLookOutRight, eyeBlinkLeft, eyeBlinkRight, eyeLookInLeft, eyeLookInRight);

  const record: TGesstureScores = {
    [EYE_GESTURE_EVENT.LOOK_UP]: computeScore(EYE_GESTURE_EVENT.LOOK_UP, eyeLookUpLeft, eyeLookUpRight),
    [EYE_GESTURE_EVENT.LOOK_DOWN]: computeScore(EYE_GESTURE_EVENT.LOOK_DOWN, eyeLookDownLeft, eyeLookDownRight),
    [EYE_GESTURE_EVENT.LOOK_LEFT]: computeScore(EYE_GESTURE_EVENT.LOOK_LEFT, eyeLookOutLeft, eyeLookInRight),
    [EYE_GESTURE_EVENT.LOOK_RIGHT]: computeScore(EYE_GESTURE_EVENT.LOOK_RIGHT, eyeLookOutRight, eyeLookInLeft),
    [EYE_GESTURE_EVENT.BLINK]: computeScore(EYE_GESTURE_EVENT.BLINK, eyeBlinkLeft, eyeBlinkRight),
    timestamp: new Date().toISOString(),
  };

  return record;
};

const useComputeScores = () => {
  const eyeSource = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.eyeSource);
  const historyDepth = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.gestureDetection.historyDepth);
  const gestureDetection = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.gestureDetection);
  const result = useDetectionStore(state => state.faceLandMark.result);
  const setGestureScores = useDetectionStore(state => state.setGestureScores);
  const setBenchmark = useDetectionStore(state => state.setBenchmark);

  // console.table([{ gestureDetection, historyDepth, eyeSource }]);

  const [scoresState, setScoresState] = useState<TScoreTrackingState>({ ...EmptyScoreTrackingState });
  const callCounterRef = useRef(0);

  // Reset counter every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setBenchmark({ CPS: callCounterRef.current });
      callCounterRef.current = 0;
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const updateScores = (categories: Category[]) => {
    callCounterRef.current += 1;
    // console.log('updateScores', categories);
    // console.log('updateScores', categories);
    // console.clear();
    const newScores = getNewScores(categories, eyeSource);
    if (JSON.stringify(newScores) === JSON.stringify(scoresState.last)) {
      return;
    }
    // console.log('updateScores', newScores);

    const {
      [EYE_GESTURE_EVENT.LOOK_UP]: look_up,
      [EYE_GESTURE_EVENT.LOOK_DOWN]: lookDownScore,
      [EYE_GESTURE_EVENT.LOOK_LEFT]: look_left,
      [EYE_GESTURE_EVENT.LOOK_RIGHT]: look_right,
      [EYE_GESTURE_EVENT.BLINK]: blinkScore,
      timestamp,
    } = newScores;
    const newState = { ...scoresState, last: { ...newScores } };
    const { max, normalized, historic, idle, active } = newState;

    // Coeficiente de degradación
    const degradationCoefficient = 1;

    // Actualiza los máximos y aplica degradación si no se actualizan
    max.look_up = look_up > max.look_up ? look_up : max.look_up * degradationCoefficient;
    max.look_down = lookDownScore > max.look_down ? lookDownScore : max.look_down * degradationCoefficient;
    max.look_left = look_left > max.look_left ? look_left : max.look_left * degradationCoefficient;
    max.look_right = look_right > max.look_right ? look_right : max.look_right * degradationCoefficient;
    max.blink = blinkScore > max.blink ? blinkScore : max.blink * degradationCoefficient;

    historic.push(newScores);
    if (historic.length > historyDepth) {
      historic.shift();
    }

    const averageScores = {
      [EYE_GESTURE_EVENT.LOOK_UP]: 0,
      [EYE_GESTURE_EVENT.LOOK_DOWN]: 0,
      [EYE_GESTURE_EVENT.LOOK_LEFT]: 0,
      [EYE_GESTURE_EVENT.LOOK_RIGHT]: 0,
      [EYE_GESTURE_EVENT.BLINK]: 0,
    };

    if (historic.length > 0) {
      const totalScores = historic.reduce(
        (acc, scores) => {
          acc.look_up += scores.look_up;
          acc.look_down += scores.look_down;
          acc.look_left += scores.look_left;
          acc.look_right += scores.look_right;
          acc.blink += scores.blink;
          return acc;
        },
        { ...averageScores }
      );

      const historicCount = historic.length;
      averageScores.look_up = totalScores.look_up / historicCount;
      averageScores.look_down = totalScores.look_down / historicCount;
      averageScores.look_left = totalScores.look_left / historicCount;
      averageScores.look_right = totalScores.look_right / historicCount;
      averageScores.blink = totalScores.blink / historicCount;
    }

    normalized.look_up = averageScores.look_up / max.look_up;
    normalized.look_down = averageScores.look_down / max.look_down;
    normalized.look_left = averageScores.look_left / max.look_left;
    normalized.look_right = averageScores.look_right / max.look_right;
    normalized.blink = averageScores.blink / max.blink;

    normalized.timestamp = timestamp;
    // console.log('normalized', gestureDetection);
    idle.look_up = normalized.look_up < gestureDetection[EYE_GESTURE_EVENT.LOOK_UP].inactiveThreshold;
    idle.look_down = normalized.look_down < gestureDetection[EYE_GESTURE_EVENT.LOOK_DOWN].inactiveThreshold;
    idle.look_left = normalized.look_left < gestureDetection[EYE_GESTURE_EVENT.LOOK_LEFT].inactiveThreshold;
    idle.look_right = normalized.look_right < gestureDetection[EYE_GESTURE_EVENT.LOOK_RIGHT].inactiveThreshold;
    idle.blink = normalized.blink < gestureDetection[EYE_GESTURE_EVENT.BLINK].inactiveThreshold;

    active.look_up = normalized.look_up > gestureDetection[EYE_GESTURE_EVENT.LOOK_UP].activeThreshold;
    active.look_down = normalized.look_down > gestureDetection[EYE_GESTURE_EVENT.LOOK_DOWN].activeThreshold;
    active.look_left = normalized.look_left > gestureDetection[EYE_GESTURE_EVENT.LOOK_LEFT].activeThreshold;
    active.look_right = normalized.look_right > gestureDetection[EYE_GESTURE_EVENT.LOOK_RIGHT].activeThreshold;
    active.blink = normalized.blink > gestureDetection[EYE_GESTURE_EVENT.BLINK].activeThreshold;
    setScoresState(newState);
    setGestureScores(normalized, newScores, idle, active);
  };

  const lastUpdateTimeRef = useRef(Date.now());
  const updateInterval = 1000 / 30; // 30 updates per second

  useEffect(() => {
    if (result?.faceBlendshapes[0]?.categories) {
      // const currentTime = Date.now();
      // console.log(`[UpdateScores] Time since last update: ${currentTime - lastUpdateTimeRef.current}ms`);
      // if (Date.now() - lastUpdateTimeRef.current >= updateInterval) {
      // console.log('updateScores', result.faceBlendshapes[0].categories);
      updateScores(result.faceBlendshapes[0].categories);
      // lastUpdateTimeRef.current = Date.now();
      // }
    }
  }, [result?.faceBlendshapes[0]?.categories]);

  return {
    max: scoresState.max,
    normalized: scoresState.normalized,
    idle: scoresState.idle,
    active: scoresState.active,
  };
};

export default useComputeScores;
