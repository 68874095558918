import { useEffect, useRef } from 'react';
import useDetectionStore from '@/Models/useDetectionStore';
import useGestureKeyboardStore from '@/Models/useGestureKeyboardStore';
import { TGestureConfirmation, useGestureConfirmationStore } from '@/Models/useGestureConfirmationStore';
import { EYE_GESTURE_EVENT } from '@/Models/Constants';
import { TGestureScoresFlag } from '@/Models/Types';

const { LOOK_UP, LOOK_LEFT, LOOK_RIGHT, LOOK_DOWN, BLINK } = EYE_GESTURE_EVENT;

const GESTURE_COOLDOWN = 1000; // 1 segundo de cooldown

const useEventCreator = () => {
  const {
    rangeFiltering: { onRange },
    paused,
    gestures,
  } = useDetectionStore();

  const {
    setConfirmation,
    confirmation,
    config: { timeout },
  } = useGestureConfirmationStore();

  const { setKeyboardGesture, oldGesture } = useGestureKeyboardStore();

  const lastTriggerTime = useRef<number>(0);

  const triggerSafe = async (active: TGestureScoresFlag, { completed, running, startTime }: TGestureConfirmation) => {
    let candidateGesture: EYE_GESTURE_EVENT | undefined = undefined;
    const outConfirmation: TGestureConfirmation = {
      completed: { ...completed },
      running: { ...running },
      startTime: { ...startTime },
    };

    const currentTime = new Date().getTime();
    const updateGestureConfirmation = (gestureKey: EYE_GESTURE_EVENT) => {
      if (active[gestureKey]) {
        if (running[gestureKey]) {
          if (startTime[gestureKey] + timeout[gestureKey] < currentTime) {
            candidateGesture = gestureKey;
            outConfirmation.startTime[gestureKey] = 0;
            outConfirmation.running[gestureKey] = false;
            outConfirmation.completed[gestureKey] = true;
          }
        } else {
          outConfirmation.startTime[gestureKey] = currentTime;
          outConfirmation.running[gestureKey] = true;
          outConfirmation.completed[gestureKey] = false;
        }
      } else {
        if (running[gestureKey]) {
          outConfirmation.startTime[gestureKey] = 0;
          outConfirmation.running[gestureKey] = false;
          outConfirmation.completed[gestureKey] = false;
        }
      }
    };
    updateGestureConfirmation(BLINK);
    updateGestureConfirmation(LOOK_UP);
    updateGestureConfirmation(LOOK_LEFT);
    updateGestureConfirmation(LOOK_DOWN);
    updateGestureConfirmation(LOOK_RIGHT);
    setConfirmation(outConfirmation);

    if (oldGesture !== candidateGesture && candidateGesture !== undefined) {
      setKeyboardGesture(candidateGesture);
    }
  };

  useEffect(() => {
    const currentTime = new Date().getTime();

    if (onRange && !paused && currentTime - lastTriggerTime.current >= GESTURE_COOLDOWN) {
      triggerSafe(gestures.active, confirmation);
      lastTriggerTime.current = currentTime;
      console.log('triggerSafe');
    }
  }, [gestures, onRange, paused]);
};

export default useEventCreator;
