import React from 'react';
import useComputeScores from './hooks/useComputeScores';
import useRangeFiltering from './hooks/useRangeFiltering';
import { DetectionContainer, PauseButtonContainer } from './Detector.styles';
import DetectionFeedBack from './DetectionFeedBack';
import useFaceLandMarker from '@/Services/useFaceLandMarker';
import WebcamComponent from './WebcamComponent';
import { PauseButton } from '@/Components/PauseButton';
import useEventCreator from './hooks/useEventCreator';
import { FpsCounter } from './Counters/FpsCounter';
import { ComputesCounter } from './Counters/ComputesCounter';
import { PredictionsCounter } from './Counters/PredictionsCounter';

export const Detector: React.FC = () => {
  useFaceLandMarker();
  useComputeScores();
  useRangeFiltering();
  useEventCreator();

  return (
    <DetectionContainer id="detector">
      <WebcamComponent />
      <DetectionFeedBack />
      <PauseButtonContainer>
        <PauseButton size="small" />
      </PauseButtonContainer>
      {/* <FpsCounter /> */}
      {/* <ComputesCounter /> */}
      {/* <PredictionsCounter /> */}
    </DetectionContainer>
  );
};
