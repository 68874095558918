import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EYE_GESTURE_EVENT } from '@/Models/Constants';
import useDetectionStore from '@/Models/useDetectionStore';
import { useGestureConfirmationStore } from '@/Models/useGestureConfirmationStore';

const StatusTag = styled.div<{ status: string }>`
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  transition: all 0.2s ease;

  ${({ status }) => {
    switch (status) {
      case 'inactivo':
        return `
          background: rgba(128, 128, 128, 0.1);
          color: #808080;
          border: 1px solid rgba(128, 128, 128, 0.2);
        `;
      case 'detectado':
        return `
          background: rgba(0, 123, 255, 0.1);
          color: #007bff;
          border: 1px solid rgba(0, 123, 255, 0.2);
        `;
      case 'confirmando':
        return `
          background: rgba(255, 193, 7, 0.1);
          color: #ffc107;
          border: 1px solid rgba(255, 193, 7, 0.2);
        `;
      case 'confirmado':
        return `
          background: rgba(40, 167, 69, 0.1);
          color: #28a745;
          border: 1px solid rgba(40, 167, 69, 0.2);
        `;
      default:
        return '';
    }
  }}
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

interface DetectionStatusProps {
  gesture: EYE_GESTURE_EVENT;
}

const DetectionStatus: React.FC<DetectionStatusProps> = ({ gesture }) => {
  const [status, setStatus] = useState('inactivo');
  const [locked, setLocked] = useState(false);

  const active = useDetectionStore(state => state.gestures.active[gesture]);
  const startTime = useGestureConfirmationStore(state => state.confirmation.startTime[gesture]);
  const completed = useGestureConfirmationStore(state => state.confirmation.completed[gesture]);

  useEffect(() => {
    if (locked && !active) {
      setLocked(false);
      setStatus('inactivo');
    } else if (!locked) {
      if (completed) {
        setStatus('confirmado');
        setLocked(true);
        console.log('confirmado ' + gesture);
      } else if (startTime > 0) {
        setStatus('confirmando');
      } else if (active) {
        setStatus('detectado');
      } else {
        setStatus('inactivo');
      }
    }
  }, [active, startTime, completed, locked]);

  return (
    <StatusContainer>
      <StatusTag status={status}>{status}</StatusTag>
    </StatusContainer>
  );
};

export default DetectionStatus;
