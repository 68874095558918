import { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import useDetectionStore from '@/Models/useDetectionStore';
import userOutlineGreen from '@/Assets/images/user-outline-green.png';
import userOutlineRed from '@/Assets/images/user-outline-red.png';
import { DrawingUtils, FaceLandmarker, FaceLandmarkerResult } from '@mediapipe/tasks-vision';
import styled from 'styled-components';
import Preloader from '@/Components/Common/Preloader';
import useUserSessionStore from '@/Models/useUserSessionStore';

const StyledCanvas = styled.canvas`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scaleX(-1);
  /* outline: 0.125rem solid rgba(255, 255, 255, 1); */
  border-radius: 10px;
`;

const PreloaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  position: relative;
`;

const DetectionFeedBack = () => {
  const imageRef = useRef<HTMLImageElement>();
  const greenImageRef = useRef<HTMLImageElement>();
  const redImageRef = useRef<HTMLImageElement>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationFrameRef = useRef<number>();
  const drawingUtilsRef = useRef<DrawingUtils>();

  const onRange = useDetectionStore(state => state.rangeFiltering.onRange);
  const paused = useDetectionStore(state => state.paused);
  const webcamStream = useDetectionStore(state => state.webcam.webcamStream);
  const videoElement = useDetectionStore(state => state.webcam.videoElement);
  const result = useDetectionStore(state => state.faceLandMark.result);
  const isWebcamOn = useDetectionStore(state => state.webcam.isWebcamOn);

  const eyeSource = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.eyeSource);

  const colors = useMemo(
    () => ({
      base: {
        color: onRange && !paused ? 'rgba(0,254,20,.2)' : 'rgba(224, 224, 224, 0.135)',
        lineWidth: 0.4,
      },
      highlight: {
        color: onRange && !paused ? 'rgba(17, 255, 45, 0.632)' : 'rgba(17, 255, 45, 0.324)',
        lineWidth: 2,
      },
      solid: {
        color: onRange && !paused ? 'rgba(0, 254, 21, 0.393)' : 'rgba(224, 224, 224, 0.135)',
        lineWidth: 1,
      },
      disabled: {
        color: onRange && !paused ? 'rgb(0, 0, 0)' : 'rgba(53, 53, 53, 0.135)',
        lineWidth: 0.44,
      },
    }),
    [onRange, paused]
  );

  useEffect(() => {
    const greenImg = new Image();
    const redImg = new Image();
    greenImg.src = userOutlineGreen;
    redImg.src = userOutlineRed;

    greenImageRef.current = greenImg;
    redImageRef.current = redImg;

    const loadImages = () => {
      if (greenImg.complete && redImg.complete) {
        imageRef.current = onRange || !paused ? greenImg : redImg;
      }
    };

    greenImg.onload = loadImages;
    redImg.onload = loadImages;

    return () => {
      greenImg.onload = null;
      redImg.onload = null;
    };
  }, []);

  useEffect(() => {
    if (greenImageRef.current && redImageRef.current && imageRef.current) {
      imageRef.current = onRange && !paused ? greenImageRef.current : redImageRef.current;
    }
  }, [onRange, paused]);

  useEffect(() => {
    const context = canvasRef?.current?.getContext('2d');
    if (context) {
      drawingUtilsRef.current = new DrawingUtils(context);
    }
  }, [canvasRef.current]);

  const drawFaceLandmarks = useCallback(
    ({ faceLandmarks }: FaceLandmarkerResult) => {
      if (!faceLandmarks || !drawingUtilsRef.current) return;
      // console.log('[STATUS] faceLandmarks', faceLandmarks);

      for (const landmarks of faceLandmarks) {
        drawingUtilsRef.current.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_TESSELATION, colors.base);
        drawingUtilsRef.current.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE, eyeSource.rightEye ? colors.highlight : colors.disabled);
        drawingUtilsRef.current.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW, colors.base);
        drawingUtilsRef.current.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_EYE, eyeSource.leftEye ? colors.highlight : colors.disabled);
        drawingUtilsRef.current.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW, colors.base);
        drawingUtilsRef.current.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_FACE_OVAL, colors.highlight);
        drawingUtilsRef.current.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LIPS, colors.base);
        drawingUtilsRef.current.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS, eyeSource.rightEye ? colors.highlight : colors.disabled);
        drawingUtilsRef.current.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS, eyeSource.leftEye ? colors.highlight : colors.disabled);
      }
    },
    [eyeSource, result?.faceLandmarks]
  );

  const drawSilhouette = useCallback(() => {
    if (!canvasRef?.current || !imageRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const width = canvas.width;
    const height = canvas.height;
    ctx.drawImage(imageRef.current, 0, 0, width, height);
  }, []);

  const renderFrame = useCallback(() => {
    if (!canvasRef?.current || !videoElement) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    if (!ctx || videoElement.paused || videoElement.ended) return;

    // Mantener el aspect ratio del video
    const videoAspectRatio = videoElement.videoWidth / videoElement.videoHeight;
    const canvasAspectRatio = canvas.width / canvas.height;

    let renderWidth = canvas.width;
    let renderHeight = canvas.height;
    let offsetX = 0;
    let offsetY = 0;

    if (videoAspectRatio > canvasAspectRatio) {
      renderHeight = canvas.width / videoAspectRatio;
      offsetY = (canvas.height - renderHeight) / 2;
    } else {
      renderWidth = canvas.height * videoAspectRatio;
      offsetX = (canvas.width - renderWidth) / 2;
    }

    ctx.drawImage(videoElement, offsetX, offsetY, renderWidth, renderHeight);

    drawSilhouette();
    if (result?.faceLandmarks) {
      drawFaceLandmarks(result);
    }
  }, [videoElement, result?.faceLandmarks, drawFaceLandmarks, drawSilhouette]);

  useEffect(() => {
    if (webcamStream && videoElement && result?.faceLandmarks) {
      renderFrame();
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [webcamStream, videoElement, result?.faceLandmarks, renderFrame]);

  if (!isWebcamOn) {
    return (
      <PreloaderContainer>
        <Preloader message="Iniciando cámara..." />
      </PreloaderContainer>
    );
  }

  return <StyledCanvas ref={canvasRef} width={640} height={480} />;
};

export default DetectionFeedBack;
