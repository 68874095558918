import styled from 'styled-components';

interface KeyboardSectionProps {
  $isActive: boolean;
  $isIdle: boolean;
}

export const KeyboardContainer = styled.div`
  width: calc(100% - 20px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10vh;
`;

export const KeyboardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const KeyboardSections = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 26px;
  padding: 0 13px;
  position: relative;

  &.pointer {
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }
`;

export const KeyboardSection = styled.div<KeyboardSectionProps & { $isLeft?: boolean; $isActive?: boolean; $isIdle?: boolean }>`
  position: relative;
  border-radius: 13px;
  padding: 13px;
  min-height: calc(30vh + 30px);
  outline: 5px solid ${({ $isActive, $isIdle }) => ($isActive ? 'white' : $isIdle ? 'none' : '#ffffff21')};

  /* background-color: ${({ $isActive, $isIdle }) => ($isIdle ? 'none' : $isActive ? 'white' : 'ffffff21')}; */
`;

export const KeyboardKeys = styled.div<{ $isLeft?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ $isLeft }) => ($isLeft ? 'flex-end' : 'flex-start')};
  align-content: start;
  border-color: rgba(255, 255, 255, 0.605);
  padding-top: 0px;
  border-radius: 13px;
  columns: 4;
  position: relative;
  margin-bottom: 13px;
`;

export const Key = styled.div<{ $isChar?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: wrap;
  outline: 1px solid rgba(0, 0, 0, 0.131);
  border-radius: 13px;
  color: rgb(227, 227, 227);
  background-color: rgba(0, 0, 0, 0.629);
  font-size: ${props => (props.$isChar ? '2em' : '1em')};
  width: 18%;
  height: 10vh !important;
  padding: 0 10px;
  margin: 5px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  line-height: 25px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  text-shadow: 2px 2px 4px rgb(0, 0, 0);
  text-transform: uppercase;
  white-space: pre-line;
  word-break: normal;

  &.phrase {
    width: auto !important;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
