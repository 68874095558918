import { DEFAULT_FONT_SIZES, KEYBOARD_TYPE, EYE_GESTURE_EVENT, EYE_DETECTION_SOURCES } from '@/Models/Constants';

/**
 * Represents a user in the system with their authentication and personal information
 */
export interface IUser {
  authToken: string;
  role: string;
  name: string;
  birthdate: string;
  id: string;
  settings: IUserSettings;
  email: string;
}

/**
 * Defines which eyes are being used for detection
 * Can enable/disable left and right eye independently
 */
export interface IEyeDetectionSource {
  [EYE_DETECTION_SOURCES.LEFT_EYE]: boolean;
  [EYE_DETECTION_SOURCES.RIGHT_EYE]: boolean;
}

export const defaultEyeDetectionSource: IEyeDetectionSource = {
  [EYE_DETECTION_SOURCES.LEFT_EYE]: true,
  [EYE_DETECTION_SOURCES.RIGHT_EYE]: true,
};

/**
 * User settings interface that includes keyboard preferences and configurations
 * Extends settings for each keyboard type with specific keyboard settings
 */
export type IUserSettings = {
  navigation?: unknown;
  id?: string;
  keyboardDistribution?: string;
  keyboardInputMode?: string;
  version: number;
  selectedKeyboard: KEYBOARD_TYPE;
  selectedKeyboardSettings: IKeyboardSettings;
  isLandscape?: boolean;
} & {
  [K in KEYBOARD_TYPE]: IKeyboardSettings;
};

/**
 * Settings for individual gesture detection including thresholds and timing
 */
export interface IGestureSettings {
  inactiveThreshold: number;
  activeThreshold: number;
  reactionTime: number;
}

export const defaultGestureSettings: IGestureSettings = {
  inactiveThreshold: 0.3,
  activeThreshold: 0.5,
  reactionTime: 0.5,
};

/**
 * Complete configuration for all supported eye gestures
 * Includes settings for each gesture type and history depth for detection
 */
export interface IGestureDetectionSettings {
  [EYE_GESTURE_EVENT.LOOK_UP]: IGestureSettings;
  [EYE_GESTURE_EVENT.LOOK_DOWN]: IGestureSettings;
  [EYE_GESTURE_EVENT.LOOK_LEFT]: IGestureSettings;
  [EYE_GESTURE_EVENT.LOOK_RIGHT]: IGestureSettings;
  [EYE_GESTURE_EVENT.BLINK]: IGestureSettings;
  historyDepth: number;
}

export const defaultEyeDetectionSettings: IGestureDetectionSettings = {
  [EYE_GESTURE_EVENT.LOOK_UP]: {
    inactiveThreshold: 0.3,
    activeThreshold: 0.7,
    reactionTime: 0.5,
  },
  [EYE_GESTURE_EVENT.LOOK_DOWN]: {
    inactiveThreshold: 0.3,
    activeThreshold: 0.7,
    reactionTime: 0.5,
  },
  [EYE_GESTURE_EVENT.LOOK_LEFT]: {
    inactiveThreshold: 0.3,
    activeThreshold: 0.7,
    reactionTime: 0.5,
  },
  [EYE_GESTURE_EVENT.LOOK_RIGHT]: {
    inactiveThreshold: 0.3,
    activeThreshold: 0.7,
    reactionTime: 0.5,
  },
  [EYE_GESTURE_EVENT.BLINK]: {
    inactiveThreshold: 0.3,
    activeThreshold: 0.7,
    reactionTime: 0.5,
  },
  historyDepth: 8,
};

/**
 * Settings specific to keyboard configuration including gesture controls,
 * visual preferences and detection settings
 */
export interface IKeyboardSettings {
  keyboardSpeakGesture: EYE_GESTURE_EVENT;
  keyFontSize: number;
  phraseFontSize: number;
  eyeSource: IEyeDetectionSource;
  gestureDetection: IGestureDetectionSettings;
}

export const defaultUserSettings: IUserSettings = {
  version: 1,
  selectedKeyboard: KEYBOARD_TYPE.VERTICAL_SPLIT_SELECTION,
  selectedKeyboardSettings: {
    keyboardSpeakGesture: EYE_GESTURE_EVENT.LOOK_UP,
    keyFontSize: DEFAULT_FONT_SIZES.KEY_FONT_SIZE,
    phraseFontSize: DEFAULT_FONT_SIZES.PHRASE_FONT_SIZE,
    eyeSource: { ...defaultEyeDetectionSource },
    gestureDetection: { ...defaultEyeDetectionSettings },
  },
  [KEYBOARD_TYPE.VERTICAL_SPLIT_SELECTION]: {
    keyboardSpeakGesture: EYE_GESTURE_EVENT.BLINK,
    keyFontSize: DEFAULT_FONT_SIZES.KEY_FONT_SIZE,
    phraseFontSize: DEFAULT_FONT_SIZES.PHRASE_FONT_SIZE,
    eyeSource: { ...defaultEyeDetectionSource },
    gestureDetection: { ...defaultEyeDetectionSettings },
  },
  [KEYBOARD_TYPE.HORIZONTAL_SPLIT_SELECTION]: {
    keyboardSpeakGesture: EYE_GESTURE_EVENT.LOOK_UP,
    keyFontSize: DEFAULT_FONT_SIZES.KEY_FONT_SIZE,
    phraseFontSize: DEFAULT_FONT_SIZES.PHRASE_FONT_SIZE,
    eyeSource: { ...defaultEyeDetectionSource },
    gestureDetection: { ...defaultEyeDetectionSettings },
  },
  [KEYBOARD_TYPE.HORIZONTAL_TRACKING_MOUSE]: {
    keyboardSpeakGesture: EYE_GESTURE_EVENT.LOOK_UP,
    keyFontSize: DEFAULT_FONT_SIZES.KEY_FONT_SIZE,
    phraseFontSize: DEFAULT_FONT_SIZES.PHRASE_FONT_SIZE,
    eyeSource: { ...defaultEyeDetectionSource },
    gestureDetection: { ...defaultEyeDetectionSettings },
  },
};

/**
 * Represents the scores/confidence values for each gesture type at a specific timestamp
 */
export type TGesstureScores = Record<EYE_GESTURE_EVENT, number> & {
  timestamp: string;
};

export const defaultGestureScores: TGesstureScores = {
  [EYE_GESTURE_EVENT.LOOK_UP]: 0,
  [EYE_GESTURE_EVENT.LOOK_DOWN]: 0,
  [EYE_GESTURE_EVENT.LOOK_LEFT]: 0,
  [EYE_GESTURE_EVENT.LOOK_RIGHT]: 0,
  [EYE_GESTURE_EVENT.BLINK]: 0,
  timestamp: '',
};

/**
 * Boolean flags indicating whether each gesture is currently active
 */
export type TGestureScoresFlag = Record<EYE_GESTURE_EVENT, boolean>;

export const defaultGestureScoresFlag: TGestureScoresFlag = {
  [EYE_GESTURE_EVENT.LOOK_UP]: false,
  [EYE_GESTURE_EVENT.LOOK_DOWN]: false,
  [EYE_GESTURE_EVENT.LOOK_LEFT]: false,
  [EYE_GESTURE_EVENT.LOOK_RIGHT]: false,
  [EYE_GESTURE_EVENT.BLINK]: false,
};

export type TScoreTrackingState = {
  historic: TGesstureScores[];
  max: TGesstureScores;
  normalized: TGesstureScores;
  last: TGesstureScores;
  idle: TGestureScoresFlag;
  active: TGestureScoresFlag;
  confirmed: TGestureScoresFlag;
};
