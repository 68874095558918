import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import useUserSessionStore from '@/Models/useUserSessionStore';
import NotFoundPage from './Components/NotFound/NotFoundPage';
import Home from './Sections/Home/Home';
import Login from './Sections/Login/Login';
import DebugPanel from './Components/DebugPanel/DebugPanel';
import { useEffect } from 'react';
import useOrientationCheck from './Components/useOrientationCheck/useOrientationCheck';

function App() {
  const { isAuthenticated, rehydrateState } = useUserSessionStore();
  useOrientationCheck();

  useEffect(() => {
    rehydrateState();
  }, []);

  return (
    <BrowserRouter>
      {/* <DebugPanel /> */}

      <Routes>
        <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate replace to="/home" />} />
        <Route path="/home" element={isAuthenticated ? <Home /> : <Navigate replace to="/login" />} />
        <Route path="/" element={<Navigate replace to={isAuthenticated ? '/home' : '/login'} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
