import styled from 'styled-components';

interface ContainerProps {
  $isVertical: boolean;
  $isSynthesizing: boolean;
}

interface PhraseTextProps {
  $fontSize: number;
  $isSynthesizing: boolean;
}

export const StyledContainer = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${props => (props.$isVertical ? '100%' : 'calc(100% - 40px)')};
  min-height: 10vh;
  margin: 20px;
  margin-left: 20px;
  padding: 20px;
  opacity: 1;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  @media (max-height: 700px) {
    min-height: 5vh;
  }
`;

export const PhraseText = styled.h1<PhraseTextProps>`
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: ${props => props.$fontSize}px;
  color: ${props => (props.$isSynthesizing ? 'gray' : 'white')};
  text-transform: uppercase;
  font-weight: 600;
  transition:
    font-size 0.3s ease,
    color 0.3s ease;

  @media (max-height: 700px) {
    font-size: 1em !important;
  }
`;
