import React from 'react';
import settingsIcon from '@/Assets/icons/settings.svg';
import { ButtonIcon } from '@/Components/Shared/ButtonIcon';
import { ConfigButtonStyled } from './ConfigButton.styles';

interface ConfigButtonProps {
  onClick: () => void;
}

const ConfigButton: React.FC<ConfigButtonProps> = ({ onClick }) => {
  return (
    <ConfigButtonStyled onClick={onClick}>
      <ButtonIcon src={settingsIcon} alt="settings icon" />
      <span>Configurar</span>
    </ConfigButtonStyled>
  );
};

export default ConfigButton;
