import React, { useState, useMemo, useCallback } from 'react';
import useDetectionStore from '@/Models/useDetectionStore';
import GestureKeyboard from '@/Components/GestureKeyboard';
import FullScreenButton from './FullScreenButton/FullScreenButton';
import Speak from '@/Components/GestureFlag/Speak/Speak';
import LogoutButton from './LogoutButton/LogoutButton';
import ConfigButton from './ConfigButton/ConfigButton';
import Loader from '@/Components/Loader/Loader';
import ConfigModal from '@/Components/ConfigModal/ConfigModal';
import { Detector } from '@/Components/Detector/Detector';
import { KEYBOARD_TYPE } from '@/Models/Constants';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { HomeContainer, HomeMain, HomeButtons, HomeHeader, HomeBody, ButtonRow, DetectorColumn, ActionsColumn } from './Home.styles';
import MediaQueryHelper from '@/Components/MediaQueryHelper/MediaQueryHelper';
import SpeakButton from './SpeakButton/SpeakButton';

const { VERTICAL_SPLIT_SELECTION, HORIZONTAL_SPLIT_SELECTION } = KEYBOARD_TYPE;

// Componente principal memoizado
const Home: React.FC = React.memo(() => {
  // Seleccionar solo los valores necesarios del store
  const completeLandMarkSetup = useDetectionStore(state => state.faceLandMark.complete);
  const paused = useDetectionStore(state => state.paused);
  const videoElement = useDetectionStore(state => state.webcam.videoElement);
  const keyboardType = useUserSessionStore(state => state.getKeyboardType());
  const isWebcamOn = useDetectionStore(state => state.webcam.isWebcamOn);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);

  // Memoizar el estado de carga
  const isLoading = useMemo(() => {
    return (keyboardType === VERTICAL_SPLIT_SELECTION || keyboardType === HORIZONTAL_SPLIT_SELECTION) && !completeLandMarkSetup;
  }, [keyboardType, completeLandMarkSetup]);

  // Memoizar los handlers
  const handleConfigClick = useCallback(() => {
    setIsConfigModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsConfigModalOpen(false);
  }, []);

  // Debug logging memoizado para evitar re-renders
  useDebugLog(keyboardType, isLoading, completeLandMarkSetup, paused, videoElement, isWebcamOn);

  return (
    <HomeContainer id="home-container">
      {isLoading && <Loader />}
      <ConfigModal isOpen={isConfigModalOpen} onClose={handleCloseModal} />
      <MediaQueryHelper />

      <HomeMain>
        <HomeHeader>
          <HomeButtons id="home-buttons">
            <DetectorColumn id="detector-column">
              <Detector />
            </DetectorColumn>
            <ActionsColumn id="actions-column">
              <ButtonRow className="button-row">
                <FullScreenButton />
                {/* <Speak /> */}

                <SpeakButton />
              </ButtonRow>
              <ButtonRow className="button-row">
                <ConfigButton onClick={handleConfigClick} />
                <LogoutButton />
              </ButtonRow>
            </ActionsColumn>
          </HomeButtons>
        </HomeHeader>

        <HomeBody id="home-body">
          <GestureKeyboard />
        </HomeBody>
      </HomeMain>
    </HomeContainer>
  );
});

// Hook personalizado para logging
const useDebugLog = (keyboardType: string, isLoading: boolean, completeLandMarkSetup: boolean, paused: boolean, videoElement: HTMLVideoElement | undefined, isWebcamOn: boolean) => {
  useMemo(() => {
    console.table({
      'APP STATUS': 'HOME DRAW',
      'Keyboard Type': keyboardType,
      'Is Loading': isLoading,
      'Is Paused': paused,
      'Webcam is on': isWebcamOn,
      'LandMark Setup Complete': completeLandMarkSetup,
      'Video Element Present': !!videoElement,
    });
  }, [keyboardType, isLoading, completeLandMarkSetup, paused, videoElement, isWebcamOn]);
};

Home.displayName = 'Home';

export default Home;
