import React from 'react';
import { EyeControl } from './EyeControl';
import { EyeContainer } from './EyeStatusIndicator';
import { EYE_DETECTION_SOURCES } from '@/Models/Constants';
import { IndicatorWrapper } from './EyeStatusIndicator';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { IUserSessionState } from '@/Models/useUserSessionStore';
const { LEFT_EYE } = EYE_DETECTION_SOURCES;

const LeftEyeStatusIndicator: React.FC = () => {
  const left_eye = useUserSessionStore((state: IUserSessionState) => state.user.settings.selectedKeyboardSettings.eyeSource[LEFT_EYE]);

  return (
    <IndicatorWrapper $isActive={left_eye}>
      <EyeContainer>
        <EyeControl side={LEFT_EYE} />
      </EyeContainer>
    </IndicatorWrapper>
  );
};

export default LeftEyeStatusIndicator;
