import React, { useCallback } from 'react';
import { StyledKeyboardKey } from './KeyboardKey.styles';
import useUserSessionStore from '@/Models/useUserSessionStore';

interface KeyboardKeyProps {
  value: string;
  onClick?: (value: string) => void;
}

interface PhraseProps {
  text: string;
}

const Phrase: React.FC<PhraseProps> = React.memo(({ text }) => (
  <div className="phrase-container">
    {text.split(' ').map((word, index) => (
      <div key={`${word}-${index}`}>{word}</div>
    ))}
  </div>
));

Phrase.displayName = 'Phrase';

const KeyboardKey: React.FC<KeyboardKeyProps> = React.memo(({ value, onClick }) => {
  const {
    user: {
      settings: { selectedKeyboardSettings },
    },
  } = useUserSessionStore();

  const { keyFontSize } = selectedKeyboardSettings;
  const $isphrase = value.length > 1;

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(value);
    }
  }, [onClick, value]);

  return (
    <StyledKeyboardKey $isphrase={$isphrase} fontSize={keyFontSize ?? 30} onClick={handleClick}>
      {$isphrase ? <Phrase text={value} /> : value}
    </StyledKeyboardKey>
  );
});

KeyboardKey.displayName = 'KeyboardKey';

export { KeyboardKey };
