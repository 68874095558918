import * as azureSdk from 'microsoft-cognitiveservices-speech-sdk';
import { useTextToSpeechStore } from '../Models/useTextToSpeechStore';

const azureSpeechConfig = azureSdk.SpeechConfig.fromSubscription(process.env.AZURE_SPEECH_KEY || 'e7cc1f5b0b7d4dfc82693c015350d5e7', process.env.AZURE_SPEECH_SERVICE_REGION || 'eastus');

azureSpeechConfig.speechSynthesisVoiceName = 'es-AR-TomasNeural';

const useTextToSpeech = () => {
  const { isSynthesizing, setIsSynthesizing } = useTextToSpeechStore();

  const handleSynthesis = (textOutput: string): void => {
    if (!isSynthesizing) {
      setIsSynthesizing(true);
      const synthesizer = new azureSdk.SpeechSynthesizer(azureSpeechConfig);

      synthesizer.speakTextAsync(
        textOutput || 'Escribe un mensaje con tus ojos.',
        result => {
          if (result.reason === azureSdk.ResultReason.SynthesizingAudioCompleted) {
            // console.log('Síntesis completada.');
            // setIsSynthesizing(false);
          } else {
            console.error('Error en la síntesis de voz:', result.reason);
            setIsSynthesizing(false);
          }
          synthesizer.close();
        },
        error => {
          console.error('Error de síntesis:', error);
          setIsSynthesizing(false);
          synthesizer.close();
        }
      );

      // Agregar un listener para el evento de finalización
      synthesizer.synthesisCompleted = () => {
        // console.log('Síntesis de voz completada. POSTA');
        setTimeout(() => {
          setIsSynthesizing(false);
          synthesizer.close();
        }, 1000);
      };
    }
  };

  return { isSynthesizing, handleSynthesis };
};

export default useTextToSpeech;
