import styled from 'styled-components';

export const KeyboardContainer = styled.div`
  width: calc(100% - 20px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10vh;
`;

export const KeyboardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
`;

export const KeyboardKeys = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-content: start;
  border-color: rgba(255, 255, 255, 0.605);
  padding-top: 0px;
  border-radius: 13px;
  position: relative;
`;

export const CommandsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 15px;
  border-color: rgba(255, 255, 255, 0.605);
  border-radius: 13px;
`;
