import { EYE_GESTURE_EVENT, THRESHOLD_SLIDER_STEP } from '@/Models/Constants';
import useUserSessionStore from '@/Models/useUserSessionStore';
import useDetectionStore from '@/Models/useDetectionStore';
import React, { useCallback, useEffect, useState, useRef, memo, useDebugValue } from 'react';
import styled from 'styled-components';
import { SliderContainer } from '@/Components/ConfigModal/ConfigModal.styles';

const SliderTrack = styled.div`
  position: relative;
  width: 100%;
  height: 8px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  outline: 1px solid rgba(0, 123, 255, 0.3);
`;

const Range = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 123, 255, 0.1) 0%,
    rgba(0, 123, 255, 0.1) var(--left-stop),
    rgba(0, 123, 255, 0.3) var(--left-stop),
    rgba(0, 123, 255, 0.3) var(--right-stop),
    rgba(0, 255, 0, 0.6) var(--right-stop),
    rgba(0, 255, 0, 0.6) 100%
  );
  border-radius: 4px;
  pointer-events: none;
`;

const CurrentValue = styled.div<{ left: string; state: 'inactive' | 'active' | 'warning' }>`
  position: absolute;
  width: 2px;
  height: 16px;
  background: ${props => {
    switch (props.state) {
      case 'inactive':
        return 'rgba(0, 123, 255, 0.3)';
      case 'active':
        return 'rgba(0, 255, 0, 0.5)';
      case 'warning':
        return '#00ff00';
    }
  }};
  border: 1px solid
    ${props => {
      switch (props.state) {
        case 'inactive':
          return 'rgba(0, 123, 255, 0.5)';
        case 'active':
        case 'warning':
          return '#00ff0050';
      }
    }};
  border-radius: 1px;
  left: ${props => props.left};
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`;

const ThumbContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
`;

const RuleMarks = styled.div`
  position: absolute;
  width: 100%;
  height: 20px;
  bottom: -20px;
  left: 0;
`;

const Mark = styled.div<{ left: string }>`
  position: absolute;
  width: 1px;
  height: 5px;
  background: rgb(255, 255, 255);
  left: ${props => props.left};
  top: 0;
`;

const MarkLabel = styled.div<{ left: string }>`
  position: absolute;
  font-size: 10px;
  color: rgba(255, 255, 255, 1);
  left: ${props => props.left};
  transform: translateX(-50%);
  top: 8px;
  text-align: center;
  white-space: nowrap;
`;

const Thumb = styled.div<{ left: string; $isDragging?: boolean }>`
  position: absolute;
  width: 24px;
  height: 24px;
  background: #007bff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  left: ${props => props.left};
  top: 50%;
  cursor: pointer;
  transition: background 0.2s;
  touch-action: none;
  user-select: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 4;

  &:hover {
    background: #0056b3;
  }

  &:active {
    background: #004494;
    transform: translate(-50%, -50%) scale(1.1);
  }
`;

const Tooltip = styled.div<{ $visible: boolean }>`
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  background: #000000;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition: opacity 0.2s;
  pointer-events: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 5;

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    background: #000000;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

const UpdatesCounter = styled.div`
  position: absolute;
  top: -25px;
  right: 0;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  background: rgba(0, 0, 0, 0.5);
  padding: 2px 6px;
  border-radius: 4px;
`;

interface MultiRangeSliderProps {
  gesture: EYE_GESTURE_EVENT;
}

const useGestureUpdateCounter = (currentValue: number) => {
  const updateCountRef = useRef(0);
  const timeWindowRef = useRef<number[]>([]);
  const [displayedUPS, setDisplayedUPS] = useState(0);

  // Contar actualizaciones
  useEffect(() => {
    const now = Date.now();
    timeWindowRef.current.push(now);
    updateCountRef.current += 1;
  }, [currentValue]);

  // Actualizar el contador cada segundo
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const oneSecondAgo = now - 1000;
      // Limpiar actualizaciones antiguas
      timeWindowRef.current = timeWindowRef.current.filter(time => time > oneSecondAgo);
      // Actualizar el contador mostrado
      setDisplayedUPS(timeWindowRef.current.length);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return displayedUPS;
};

const useDragProxy = (initialValue: number, onChange: (value: number) => void, min: number = 0, max: number = 1) => {
  const [proxyValue, setProxyValue] = useState(initialValue);
  const isDraggingRef = useRef(false);
  const frameRef = useRef<number>();

  // Sincronizar el valor inicial
  useEffect(() => {
    if (!isDraggingRef.current) {
      setProxyValue(initialValue);
    }
  }, [initialValue]);

  const updateValue = useCallback(
    (newValue: number) => {
      const clampedValue = Math.max(min, Math.min(max, newValue));
      setProxyValue(clampedValue);

      // Usar requestAnimationFrame para throttle de actualizaciones
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
      frameRef.current = requestAnimationFrame(() => {
        onChange(clampedValue);
      });
    },
    [min, max, onChange]
  );

  return {
    value: proxyValue,
    setValue: updateValue,
    setDragging: (dragging: boolean) => {
      isDraggingRef.current = dragging;
    },
  };
};

const MultiRangeSliderComponent: React.FC<MultiRangeSliderProps> = ({ gesture }) => {
  const gestureDetection = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.gestureDetection);
  const setGestureDetectionSettings = useUserSessionStore(state => state.setGestureDetectionSettings);
  const gestures = useDetectionStore(state => state.gestures);
  const currentValue = gestures.scores[gesture];
  const updatesPerSecond = useGestureUpdateCounter(currentValue);

  const { inactiveThreshold, activeThreshold } = gestureDetection[gesture];

  const inactiveProxy = useDragProxy(
    inactiveThreshold,
    useCallback(
      (value: number) => {
        setGestureDetectionSettings(gesture, {
          ...gestureDetection[gesture],
          inactiveThreshold: value,
        });
      },
      [gesture, gestureDetection, setGestureDetectionSettings]
    ),
    0,
    activeThreshold - THRESHOLD_SLIDER_STEP
  );

  const activeProxy = useDragProxy(
    activeThreshold,
    useCallback(
      (value: number) => {
        setGestureDetectionSettings(gesture, {
          ...gestureDetection[gesture],
          activeThreshold: value,
        });
      },
      [gesture, gestureDetection, setGestureDetectionSettings]
    ),
    inactiveThreshold + THRESHOLD_SLIDER_STEP,
    1
  );

  const createDragHandler = (proxy: ReturnType<typeof useDragProxy>) => (e: React.MouseEvent) => {
    e.preventDefault();
    proxy.setDragging(true);

    const handleMouseMove = (e: MouseEvent) => {
      if (!sliderRef.current) return;
      const rect = sliderRef.current.getBoundingClientRect();
      const percent = Math.max(0, Math.min(100, ((e.clientX - rect.left) / rect.width) * 100)) / 100;
      proxy.setValue(percent);
    };

    const handleMouseUp = () => {
      proxy.setDragging(false);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const sliderRef = useRef<HTMLInputElement>(null);
  const range = useRef<HTMLDivElement>(null);
  const [isDraggingInactive, setIsDraggingMin] = useState(false);
  const [isDraggingACtive, setIsDraggingMax] = useState(false);

  const handleTouchStart = (handler: (e: React.MouseEvent) => void) => (e: React.TouchEvent) => {
    const touch = e.touches[0];
    handler({
      ...e,
      clientX: touch.clientX,
      preventDefault: () => e.preventDefault(),
    } as unknown as React.MouseEvent);
  };

  useEffect(() => {
    const minPercent = inactiveProxy.value * 100;
    const maxPercent = activeProxy.value * 100;

    if (range.current) {
      range.current.style.setProperty('--left-stop', `${minPercent}%`);
      range.current.style.setProperty('--right-stop', `${maxPercent}%`);
    }
  }, [inactiveProxy.value, activeProxy.value]);

  const generateMarks = () => {
    const marks = [];
    const step = 0.1;
    const numSteps = Math.floor(1 / step) + 1;

    for (let i = 0; i < numSteps; i++) {
      const value = i * step;
      const percent = value * 100;
      const isMainMark = i % 2 === 0;
      const shouldShowLabel = i % 5 === 0;

      marks.push(
        <React.Fragment key={value}>
          <Mark
            left={`${percent}%`}
            style={{
              height: isMainMark ? '8px' : '4px',
              opacity: isMainMark ? 0.3 : 0.15,
            }}
          />
          {shouldShowLabel && <MarkLabel left={`${percent}%`}>{value.toFixed(1)}</MarkLabel>}
        </React.Fragment>
      );
    }
    return marks;
  };

  const getCurrentValueState = useCallback((): 'inactive' | 'active' | 'warning' => {
    if (currentValue < inactiveThreshold) return 'inactive';
    if (currentValue > activeThreshold) return 'warning';
    return 'active';
  }, [currentValue, inactiveThreshold, activeThreshold]);

  return (
    <SliderContainer>
      <UpdatesCounter>{updatesPerSecond} ups</UpdatesCounter>
      <SliderTrack ref={sliderRef}>
        <Range ref={range} />
        <CurrentValue left={`${currentValue * 100}%`} state={getCurrentValueState()} />
        <ThumbContainer>
          <Thumb
            left={`${inactiveProxy.value * 100}%`}
            onMouseDown={createDragHandler(inactiveProxy)}
            onTouchStart={handleTouchStart(createDragHandler(inactiveProxy))}
            $isDragging={isDraggingInactive}
          >
            <Tooltip $visible={isDraggingInactive}>{inactiveProxy.value.toFixed(2)}</Tooltip>
          </Thumb>
          <Thumb left={`${activeProxy.value * 100}%`} onMouseDown={createDragHandler(activeProxy)} onTouchStart={handleTouchStart(createDragHandler(activeProxy))} $isDragging={isDraggingACtive}>
            <Tooltip $visible={isDraggingACtive}>{activeProxy.value.toFixed(2)}</Tooltip>
          </Thumb>
        </ThumbContainer>
        <RuleMarks>{generateMarks()}</RuleMarks>
      </SliderTrack>
    </SliderContainer>
  );
};

export const MultiRangeSlider = memo(MultiRangeSliderComponent, (prevProps, nextProps) => {
  // Solo re-renderizar si el gesto cambia
  return prevProps.gesture === nextProps.gesture;
});
