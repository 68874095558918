import React from 'react';
import styled from 'styled-components';
import { EYE_GESTURE_EVENT, GestureLabels, KEYBOARD_AVAILABLE_SPEAK_GESTURES, KEYBOARD_TYPE } from '@/Models/Constants';
import { Container, Title } from '@/Components/ConfigModal/ConfigModal.styles';
import useUserSessionStore from '@/Models/useUserSessionStore';

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledSelect = styled.select`
  background-color: #2a2a2a;
  color: white;
  padding: 13px 26px;
  border: 1px solid #007bff;

  border-radius: 6px;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: #ffffff;
  }

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  option {
    background-color: #1a1a1a;
  }
`;

const DisabledMessage = styled.p`
  color: #666;
  font-style: italic;
  margin: 4px 0;
  font-size: 0.9rem;
`;

const SpeakGestureSelector: React.FC = () => {
  const {
    user: {
      settings: {
        selectedKeyboard,
        selectedKeyboardSettings: { keyboardSpeakGesture },
      },
    },
    // setSpeakGesture,
  } = useUserSessionStore();

  // const selectedKeyboard = useUserSessionStore(state => state.user.settings.selectedKeyboard);
  // const keyboardSpeakGesture = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.keyboardSpeakGesture);
  // const setSpeakGesture = useUserSessionStore(state => state.setSpeakGesture);

  const handleSpeakGestureChange = React.useCallback((gesture: EYE_GESTURE_EVENT) => {
    console.log(gesture);
    // setSpeakGesture(gesture);
  }, []);

  const availableGestures = KEYBOARD_AVAILABLE_SPEAK_GESTURES[selectedKeyboard as KEYBOARD_TYPE];

  const hasAvailableGestures = availableGestures ? availableGestures.length > 0 : false;
  React.useEffect(() => {
    if (hasAvailableGestures && !availableGestures.includes(keyboardSpeakGesture)) {
      handleSpeakGestureChange(availableGestures[0]);
    }
  }, [keyboardSpeakGesture, handleSpeakGestureChange, selectedKeyboard]);

  return (
    <Container>
      <Title>Gesto Seleccionado</Title>
      <SelectContainer>
        <StyledSelect value={keyboardSpeakGesture} onChange={e => handleSpeakGestureChange(e.target.value as EYE_GESTURE_EVENT)} disabled={!hasAvailableGestures}>
          {hasAvailableGestures ? (
            availableGestures.map((gesture: EYE_GESTURE_EVENT) => (
              <option key={gesture} value={gesture}>
                {GestureLabels[gesture]}
              </option>
            ))
          ) : (
            <option value="">No hay gestos disponibles</option>
          )}
        </StyledSelect>
        {!hasAvailableGestures && <DisabledMessage>Este tipo de teclado no tiene gestos de habla disponibles</DisabledMessage>}
      </SelectContainer>
    </Container>
  );
};

export default SpeakGestureSelector;
