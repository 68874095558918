import React, { useEffect } from 'react';
import { EYE_GESTURE_EVENT } from '@/Models/Constants';
import useGestureKeyboardStore from '@/Models/useGestureKeyboardStore';
import Check from '../../GestureFlag/Check/Check';
import useDetectionStore from '@/Models/useDetectionStore';
import PhraseContainer from '../../PhraseContainer/PhraseContainer';
import { KeyboardContainer, KeyboardContent, KeyboardSections, KeyboardSection, KeyboardKeys } from './KeyboardHorzonalSplit.styles';
import { KeyboardKey } from '../components/KeyboardKey';
const { LOOK_LEFT, LOOK_RIGHT } = EYE_GESTURE_EVENT;

export const KeyboardHorizontalSplit: React.FC = () => {
  const left = useGestureKeyboardStore(state => state.left);
  const right = useGestureKeyboardStore(state => state.right);
  const currentGesture = useGestureKeyboardStore(state => state.currentGesture);
  const init = useGestureKeyboardStore(state => state.init);
  const pick = useGestureKeyboardStore(state => state.pick);

  const isActive = useDetectionStore(state => state.gestures.active);
  const isIdle = useDetectionStore(state => state.gestures.idle);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (currentGesture === LOOK_LEFT) {
      pickLeft();
    } else if (currentGesture === LOOK_RIGHT) {
      pickRight();
    }
  }, [currentGesture]);

  const pickLeft = () => {
    pick(left.keys, left.commands);
  };

  const pickRight = () => {
    pick(right.keys, right.commands);
  };

  // console.log(isActive[LOOK_LEFT], isIdle[LOOK_LEFT]);
  return (
    <KeyboardContainer id="gesture-keyboard">
      <PhraseContainer />
      <KeyboardContent>
        <KeyboardSections>
          <KeyboardSection $isLeft={true} $isActive={isActive[LOOK_LEFT]} $isIdle={isActive[LOOK_RIGHT]}>
            {left.keys.length > 0 && (
              <KeyboardKeys $isLeft={true}>
                {[...left.keys].map((key: string) => (
                  <KeyboardKey key={key} value={key} />
                ))}
              </KeyboardKeys>
            )}
            {left.commands.length > 0 && (
              <KeyboardKeys $isLeft={true}>
                {[...left.commands].map((key: string) => (
                  <KeyboardKey key={key} value={key} />
                ))}
              </KeyboardKeys>
            )}
            <Check gesture={LOOK_LEFT} />
          </KeyboardSection>
          <KeyboardSection $isActive={isActive[LOOK_RIGHT]} $isIdle={isActive[LOOK_LEFT]}>
            {right.keys.length > 0 && (
              <KeyboardKeys>
                {[...right.keys].map((key: string) => (
                  <KeyboardKey key={key} value={key} />
                ))}
              </KeyboardKeys>
            )}
            {right.commands.length > 0 && (
              <KeyboardKeys>
                {[...right.commands].map((key: string) => (
                  <KeyboardKey key={key} value={key} />
                ))}
              </KeyboardKeys>
            )}
            <Check gesture={LOOK_RIGHT} />
          </KeyboardSection>
        </KeyboardSections>
      </KeyboardContent>
    </KeyboardContainer>
  );
};
