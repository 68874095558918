import React from 'react';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { styled } from 'styled-components';
import { IUserSessionState } from '@/Models/useUserSessionStore';

interface LegendContainerProps {
  isError: boolean;
}

const LegendContainer = styled.div<LegendContainerProps>`
  margin: 10px 0;
  padding: 23px;
  background-color: #000000;
  border-radius: 4px;
  text-align: center;
  font-size: 1.5rem;
  color: ${props => (props.isError ? '#dc3545' : 'inherit')};
  font-weight: ${props => (props.isError ? 'bold' : 'normal')};
`;

export const EyeStatusLegend: React.FC = () => {
  const selectedKeyboardSettings = useUserSessionStore((state: IUserSessionState) => state.user.settings.selectedKeyboardSettings);
  const { leftEye, rightEye } = selectedKeyboardSettings.eyeSource;

  const getLegendText = () => {
    if (leftEye && rightEye) {
      return 'Usando ambos ojos para reconocer gestos';
    } else if (leftEye) {
      return 'Usando solo ojo izquierdo para reconocer gestos';
    } else if (rightEye) {
      return 'Usando solo ojo derecho para reconocer gestos';
    }
    return 'No hay ojos configurados para reconocer gestos';
  };

  const isError = !leftEye && !rightEye;

  return <LegendContainer isError={isError}>{getLegendText()}</LegendContainer>;
};

export default EyeStatusLegend;
