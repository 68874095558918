import { Title } from '@/Components/ConfigModal/ConfigModal.styles';
import { EYE_GESTURE_EVENT } from '@/Models/Constants';
import PerGestureSettings from './PerGestureSettings';

const { LOOK_LEFT, LOOK_RIGHT, LOOK_UP, LOOK_DOWN, BLINK } = EYE_GESTURE_EVENT;

const GestureControl: React.FC = () => {
  return (
    <div>
      <Title>Configuración Gesto</Title>

      <PerGestureSettings gesture={LOOK_LEFT} title="Rango de detección gesto mirar a la izquierda" />

      <PerGestureSettings gesture={BLINK} title="Detección gesto parpadear" />
    </div>
  );
};

export default GestureControl;
