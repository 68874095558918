import React from 'react';
import styled from 'styled-components';
import { Container, Title } from '@/Components/ConfigModal/ConfigModal.styles';
import DetectionFeedBack from '@/Components/Detector/DetectionFeedBack';
import { PauseButton } from '@/Components/PauseButton';
import { DetectionContainer } from '@/Components/Detector/Detector.styles';

// Estilos específicos para la preview
const PreviewContainer = styled(DetectionContainer)`
  width: 20vw;
  max-width: 320px;
  margin: 0 auto;
  margin-top: 10px;
  position: relative;
`;

const DetectionControl: React.FC = () => {
  return (
    <Container>
      <Title>Control de Detección</Title>
      {/* <ToggleButton $active={!paused} onClick={toggleDetection} type="button"> */}
      <PreviewContainer>
        <DetectionFeedBack />
        <PauseButton size="large" />
      </PreviewContainer>
      {/* </ToggleButton> */}
    </Container>
  );
};

export default DetectionControl;
