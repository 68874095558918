import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { COMMANDS, KEY_LIST, LEFT_COMMANDS, RIGHT_COMMANDS } from '@/Models/Constants';
import useUserSessionStore from './useUserSessionStore';
import { EYE_GESTURE_EVENT } from '@/Models/Constants';
/**
 * Interfaz que define la estructura del estado del store del teclado de gestos.
 */

export interface IKeyboardGestureStore {
  config: {
    validatationTime: number;
    initialKeys: string[];
  };
  oldGesture: EYE_GESTURE_EVENT | undefined;
  currentGesture: EYE_GESTURE_EVENT | undefined;
  phrase: string;
  leftSide: string[];
  rightSide: string[];

  left: {
    keys: string[];
    commands: string[];
  };
  right: {
    keys: string[];
    commands: string[];
  };

  restore: () => void;
  setKeyboardGesture: (keyboardGesture: EYE_GESTURE_EVENT | undefined) => void;
  init: () => void;
  pick: (letters: string[], commands: string[]) => void;
  pickKey: (key: string) => void;
  isPaused: boolean;
  togglePause: () => void;
}

/**
 * Determina las teclas y comandos de la mitad izquierda.
 * @param keys - Lista de teclas.
 * @param commands - Lista de comandos.
 * @returns Un objeto con las teclas y comandos de la mitad izquierda.
 */
const resolveLeft = (keys: string[], commands: string[]) => {
  const out: {
    keys: string[];
    commands: string[];
  } = {
    keys: [],
    commands: [],
  };

  // si hay una tecla y no hay comandos
  if (keys.length === 1 && commands.length === 0) {
    out.keys = KEY_LIST.slice(0, KEY_LIST.length / 2);
    out.commands = [...LEFT_COMMANDS];
    // si hay una tecla y un comando
  } else if (keys.length === 1 && commands.length === 1) {
    out.keys = keys;
    out.commands = [];
    // si hay una tecla y mas de un comando
  } else if (keys.length === 1 && commands.length > 1) {
    out.keys = keys;
    out.commands = [];
    // si no hay teclas y un comando
  } else if (keys.length == 0 && commands.length == 1) {
    out.keys = KEY_LIST.slice(0, KEY_LIST.length / 2);
    out.commands = [...LEFT_COMMANDS];
    // si no hay teclas y mas de un comando
  } else if (keys.length == 0 && commands.length > 1) {
    out.keys = [];
    out.commands = commands.slice(0, commands.length / 2);
    // si hay mas de una tecla y no hay comandos
  } else {
    out.keys = keys.slice(0, keys.length / 2);
    out.commands = [...LEFT_COMMANDS];
  }

  return out;
};

/**
 * Determina las teclas y comandos de la mitad derecha.
 * @param keys - Lista de teclas.
 * @param commands - Lista de comandos.
 * @returns Un objeto con las teclas y comandos de la mitad derecha.
 */
const resolveRight = (keys: string[], commands: string[]) => {
  const out: {
    keys: string[];
    commands: string[];
  } = {
    keys: [],
    commands: [],
  };

  // si hay una tecla y no hay comandos
  if (keys.length === 1 && commands.length === 0) {
    out.keys = KEY_LIST.slice(KEY_LIST.length / 2);
    out.commands = [...RIGHT_COMMANDS];
    // si hay una tecla y un comando
  } else if (keys.length === 1 && commands.length === 1) {
    out.keys = [];
    out.commands = commands;
    // si hay una tecla y mas de un comando
  } else if (keys.length === 1 && commands.length > 1) {
    out.keys = [];
    out.commands = commands;
    // si no hay teclas y un comando
  } else if (keys.length == 0 && commands.length == 1) {
    out.keys = KEY_LIST.slice(KEY_LIST.length / 2);
    out.commands = [...RIGHT_COMMANDS];
    // si no hay teclas y mas de un comando
  } else if (keys.length == 0 && commands.length > 1) {
    out.keys = [];
    out.commands = commands.slice(commands.length / 2);
    // si hay mas de una tecla y no hay comandos
  } else {
    out.keys = keys.slice(keys.length / 2);
    out.commands = [...RIGHT_COMMANDS];
  }

  return out;
};

/**
 * Resuelve el último carácter basado en los comandos de control.
 * @param phrase - Frase actual.
 * @param keys - Letras o comandos.
 * @returns La frase actualizada.
 */
const resolveLastCharacter = (phrase: string, keys: string[], commands: string[]) => {
  if (keys.length === 0 && commands.length === 1) {
    if (commands[0] === COMMANDS.DELETE) return phrase.slice(0, phrase.length - 1);
    if (commands[0] === COMMANDS.DELETE_ALL) return '';
    if (commands[0] === COMMANDS.SPACE) return phrase + ' ';
    if (commands[0] === COMMANDS.SPEAK) return phrase;
    if (commands[0] === COMMANDS.RESET) return phrase;
  } else if (keys.length === 1 && commands.length === 0) {
    return phrase + keys[0];
  }

  return phrase;
};

/**
 * Devuelve un gesto de hablar si el comando correspondiente es detectado.
 * @param keys - Letras o comandos.
 * @returns Un gesto de hablar o undefined.
 */
const resolveTalkGesture = (keys: string[], commands: string[]) => {
  if (keys.length === 0 && commands.length === 1 && commands[0] === COMMANDS.SPEAK) {
    return useUserSessionStore.getState().getSpeakGesture();
  }
  return undefined;
};

/**
 * Store de Zustand que mantiene el estado del teclado de gestos.
 */
export const useGestureKeyboardStore = create<IKeyboardGestureStore>()(
  persist(
    (set, get) => ({
      config: {
        validatationTime: 1000,
        initialKeys: [...KEY_LIST],
      },
      oldGesture: undefined,
      currentGesture: undefined,
      phrase: '',
      leftSide: [],
      rightSide: [],
      left: {
        keys: [],
        commands: [],
      },
      right: {
        keys: [],
        commands: [],
      },
      restore: () =>
        set(() => ({
          config: {
            validatationTime: 1000,
            initialKeys: [...KEY_LIST],
          },
          oldGesture: undefined,
          currentGesture: undefined,
          phrase: '',
          leftSide: [],
          rightSide: [],
          left: {
            keys: [],
            commands: [],
          },
          right: {
            keys: [],
            commands: [],
          },
        })),
      setKeyboardGesture: keyboardGesture =>
        set(state => ({
          ...state,
          oldGesture: state.currentGesture || keyboardGesture,
          currentGesture: keyboardGesture,
        })),
      resetKeyboardKeys: () =>
        set(state => ({
          phrase: '',

          left: {
            keys: state.config.initialKeys.slice(0, state.config.initialKeys.length / 2),
            commands: [COMMANDS.DELETE, COMMANDS.DELETE_ALL],
          },
          right: {
            keys: state.config.initialKeys.slice(state.config.initialKeys.length / 2),
            commands: [COMMANDS.SPACE, COMMANDS.SPEAK],
          },
          currentGesture: undefined,
        })),
      init: () =>
        set(state => ({
          phrase: '',

          left: {
            keys: state.config.initialKeys.slice(0, state.config.initialKeys.length / 2),
            commands: [...LEFT_COMMANDS],
          },
          right: {
            keys: state.config.initialKeys.slice(state.config.initialKeys.length / 2),
            commands: [...RIGHT_COMMANDS],
          },

          currentGesture: undefined,
        })),
      pick: (keys: string[], commands: string[]) => {
        set(state => {
          return {
            phrase: resolveLastCharacter(state.phrase, keys, commands),
            left: resolveLeft(keys, commands),
            right: resolveRight(keys, commands),
            currentGesture: resolveTalkGesture(keys, commands),
          };
        });
      },
      pickKey: (key: string) => {
        set(state => {
          let newPhrase = state.phrase;
          let newGesture = state.currentGesture;

          // Verificar si el key es un comando
          if (Object.values(COMMANDS).includes(key as COMMANDS)) {
            switch (key) {
              case COMMANDS.DELETE:
                newPhrase = state.phrase.slice(0, -1);
                break;
              case COMMANDS.DELETE_ALL:
                newPhrase = '';
                break;
              case COMMANDS.SPACE:
                newPhrase += ' ';
                break;
              case COMMANDS.SPEAK:
                newGesture = useUserSessionStore.getState().getSpeakGesture();
                break;
              case COMMANDS.RESET:
                // Reiniciar el teclado (podrías llamar a la función init aquí)
                get().init();
                return {}; // No necesitamos actualizar el estado aquí, init() lo hará
            }
          } else {
            // Si no es un comando, asumimos que es una letra y la añadimos a la frase
            newPhrase += key;
          }

          return {
            currentGesture: newGesture,
            phrase: newPhrase,
          };
        });
      },
      isPaused: false,
      togglePause: () => set(state => ({ isPaused: !state.isPaused })),
    }),
    {
      name: 'gesture-keyboard-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
export { RIGHT_COMMANDS, LEFT_COMMANDS };

export default useGestureKeyboardStore;
