import React, { useEffect, useState } from 'react';
import { BREAKPOINTS } from '@/Layout/breakpoints';
import { HelperContainer, InfoRow } from './MediaQueryHelper.styles';

// Ratios típicos de dispositivos móviles modernos:
// iPhone 12/13/14: 19.5:9 (2.167)
// Samsung Galaxy S21/S22: 20:9 (2.222)
// Google Pixel 6/7: 19.5:9 (2.167)
const MOBILE_RATIO_THRESHOLD = 1.7; // Ratio mínimo para considerar un dispositivo como móvil

const MediaQueryHelper: React.FC = () => {
  const [mediaInfo, setMediaInfo] = useState({
    deviceType: '',
    orientation: '',
    dimensions: '',
    ratio: '',
  });

  useEffect(() => {
    const updateMediaInfo = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const orientation = height > width ? 'portrait' : 'landscape';
      const aspectRatio =
        orientation === 'portrait'
          ? height / width // En portrait usamos height/width
          : width / height; // En landscape usamos width/height

      let deviceType = '';

      // Función para determinar si es un móvil basado en el ratio
      const isMobileRatio = () => {
        return aspectRatio >= MOBILE_RATIO_THRESHOLD;
      };

      // Determinar tipo de dispositivo basado en orientación específica
      if (orientation === 'portrait') {
        if (width <= BREAKPOINTS.MOBILE_MAX_WIDTH && height >= BREAKPOINTS.MOBILE_MIN_HEIGHT) {
          deviceType = isMobileRatio() ? 'mobile portrait (ratio)' : 'mobile portrait (size)';
        } else if (width >= BREAKPOINTS.TABLET_MIN_WIDTH && width <= BREAKPOINTS.TABLET_MAX_WIDTH && height >= BREAKPOINTS.TABLET_MIN_HEIGHT) {
          deviceType = 'tablet portrait';
        }
      } else {
        // landscape
        if (height <= BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT) {
          deviceType = 'mobile landscape';
        } else if (width >= BREAKPOINTS.TABLET_MIN_HEIGHT && width <= BREAKPOINTS.TABLET_MAX_HEIGHT) {
          deviceType = 'tablet landscape';
        }
      }

      // Si no se determinó un tipo específico y el ratio es de móvil
      if (!deviceType && isMobileRatio()) {
        deviceType = `mobile (ratio only) ${orientation}`;
      } else if (!deviceType) {
        deviceType = `desktop ${orientation}`;
      }

      setMediaInfo({
        deviceType,
        orientation,
        dimensions: `${width}x${height}`,
        ratio: `${aspectRatio.toFixed(3)}`,
      });
    };

    // Initial check
    updateMediaInfo();

    // Add event listener for resize
    window.addEventListener('resize', updateMediaInfo);
    window.addEventListener('orientationchange', updateMediaInfo);

    // Cleanup
    return () => {
      window.removeEventListener('resize', updateMediaInfo);
      window.removeEventListener('orientationchange', updateMediaInfo);
    };
  }, []);

  return (
    <HelperContainer>
      <InfoRow>
        <span>Device:</span>
        <span>{mediaInfo.deviceType}</span>
      </InfoRow>
      <InfoRow>
        <span>Orientation:</span>
        <span>{mediaInfo.orientation}</span>
      </InfoRow>
      <InfoRow>
        <span>Size:</span>
        <span>{mediaInfo.dimensions}</span>
      </InfoRow>
      <InfoRow>
        <span>Ratio:</span>
        <span>{mediaInfo.ratio}</span>
      </InfoRow>
    </HelperContainer>
  );
};

export default MediaQueryHelper;
