import styled from 'styled-components';

export const StyledKeyboardKey = styled.div<{
  $isphrase: boolean;
  fontSize: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-wrap: wrap;
  outline: 1px solid rgba(0, 0, 0, 0.9);
  border-radius: 13px;
  color: rgb(227, 227, 227);
  background-color: rgba(0, 0, 0, 0.8);
  font-size: ${({ $isphrase, fontSize }) => (!$isphrase ? `${fontSize}px` : `${fontSize * 0.7}px`)};
  /* width: ${({ $isphrase }) => ($isphrase ? 'auto' : '5%')}; */
  width: auto;
  max-width: ${({ $isphrase }) => ($isphrase ? '100%' : '4%')};
  min-width: 50px;
  height: 100px !important;
  padding: 0 1em;
  margin: 5px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  line-height: ${({ $isphrase }) => ($isphrase ? '1.1em' : '25px')};
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  text-shadow: 2px 2px 4px rgb(0, 0, 0);
  text-transform: uppercase;
  white-space: pre-line;
  word-break: normal;
  overflow: ${({ $isphrase }) => ($isphrase ? 'hidden' : 'visible')};
  text-overflow: ${({ $isphrase }) => ($isphrase ? 'ellipsis' : 'clip')};

  .pointer & {
    width: 20%;
    margin: 4px;
  }

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  }

  &.active {
    outline: 1px solid rgba(255, 255, 255, 0.469);
    color: rgb(227, 227, 227);
    background-color: rgba(0, 0, 0, 1);
  }

  &.idle {
    opacity: 0.7;
    color: rgba(217, 217, 217, 0.473);
  }

  div {
    margin: ${({ $isphrase }) => ($isphrase ? '2px 0' : '0')};
  }
`;
