import React from 'react';
import styled from 'styled-components';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { DEFAULT_FONT_SIZES } from '@/Models/Constants';
import { Container, Title } from '@/Components/ConfigModal/ConfigModal.styles';
import { SliderContainer, SliderGroup } from '@/Components/ConfigModal/ConfigModal.styles';

const SliderLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);

  span:last-child {
    min-width: 40px;
    text-align: right;
    color: #007bff;
  }
`;

const Slider = styled.input`
  width: 100%;
  height: 4px;
  background: #1a1a1a;
  border-radius: 2px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: #007bff;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background: #0056b3;
    }
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #007bff;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    transition: background 0.2s;

    &:hover {
      background: #0056b3;
    }
  }
`;

const FontSizeControl: React.FC = () => {
  const {
    user: {
      settings: { selectedKeyboardSettings },
    },
    setKeyFontSize,
    setPhraseFontSize,
  } = useUserSessionStore();

  const keyFontSize = selectedKeyboardSettings?.keyFontSize ?? DEFAULT_FONT_SIZES.KEY_FONT_SIZE;
  const phraseFontSize = selectedKeyboardSettings?.phraseFontSize ?? DEFAULT_FONT_SIZES.PHRASE_FONT_SIZE;

  const handleKeyFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = Number(e.target.value);
    if (!isNaN(newSize)) {
      setKeyFontSize(newSize);
    }
  };

  const handlePhraseFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = Number(e.target.value);
    if (!isNaN(newSize)) {
      setPhraseFontSize(newSize);
    }
  };

  return (
    <Container>
      <Title>Tamaño del Texto</Title>
      <SliderContainer>
        <SliderGroup>
          <SliderLabel>
            <span>Tamaño del texto en las teclas</span>
            <span>{keyFontSize}px</span>
          </SliderLabel>
          <Slider type="range" min="20" max="38" value={keyFontSize} onChange={handleKeyFontSizeChange} />
        </SliderGroup>

        <SliderGroup>
          <SliderLabel>
            <span>Tamaño del texto de la frase pronunciar</span>
            <span>{phraseFontSize}px</span>
          </SliderLabel>
          <Slider type="range" min="20" max="38" value={phraseFontSize} onChange={handlePhraseFontSizeChange} />
        </SliderGroup>
      </SliderContainer>
    </Container>
  );
};

export default FontSizeControl;
