import React from 'react';
// import { Container } from '@/Components/ConfigModal/ConfigModal.styles';
import { EyeControl } from './EyeControl';
import { EyeContainer, IndicatorWrapper } from './EyeStatusIndicator';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { IUserSessionState } from '@/Models/useUserSessionStore';
import { EYE_DETECTION_SOURCES } from '@/Models/Constants';
const { RIGHT_EYE } = EYE_DETECTION_SOURCES;

const EyeStatusIndicator: React.FC = () => {
  const right_eye = useUserSessionStore((state: IUserSessionState) => state.user.settings.selectedKeyboardSettings.eyeSource[RIGHT_EYE]);

  return (
    <IndicatorWrapper $isActive={right_eye}>
      <EyeContainer>
        <EyeControl side={RIGHT_EYE} />
      </EyeContainer>
    </IndicatorWrapper>
  );
};

export default EyeStatusIndicator;
