import React from 'react';
import { GroupContainer, SliderLabel } from '@/Components/ConfigModal/ConfigModal.styles';
import { MultiRangeSlider } from './MultiRangeSlider/MultiRangeSlider';
import { EYE_GESTURE_EVENT } from '@/Models/Constants';
import ConfirmationSlider from './ConfirmationSlider';
import ConfirmationFeedback from './ConfirmationFeedback';
import DetectionStatus from './DetectionStatus';
import styled from 'styled-components';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 80% 20%;
  gap: 16px;
  width: 100%;
`;

const GridItem = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
`;

interface PerGestureSettingsProps {
  gesture: EYE_GESTURE_EVENT;
  title: string;
}

const PerGestureSettings: React.FC<PerGestureSettingsProps> = ({ gesture, title }) => {
  return (
    <GroupContainer>
      <SliderLabel>
        <span>{title}</span>
      </SliderLabel>
      <GridContainer>
        <GridItem>
          <ConfirmationSlider gesture={gesture} />
        </GridItem>
        <GridItem>
          <ConfirmationFeedback gesture={gesture} />
        </GridItem>
        <GridItem>
          <MultiRangeSlider gesture={gesture} />
        </GridItem>
        <GridItem>
          <DetectionStatus gesture={gesture} />
        </GridItem>
      </GridContainer>
    </GroupContainer>
  );
};

export default PerGestureSettings;
