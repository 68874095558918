import { BoundingBox, FaceLandmarker, FaceLandmarkerOptions, FaceLandmarkerResult } from '@mediapipe/tasks-vision';
import { create } from 'zustand';
import { defaultGestureScores, defaultGestureScoresFlag, TGesstureScores, TGestureScoresFlag } from '@/Models/Types';
import Webcam from 'react-webcam';

export type TOrientation = 'PORTRAIT' | 'LANDSCAPE';

export type TDetectionConfig = {
  faceLandMark: {
    active: boolean;
    faceLandmarkerOptions: FaceLandmarkerOptions;
    wasmFileBasePath: string;
  };
  rangeFiltering: {
    active: boolean;
    faceWidthMin: number;
    faceWidthMax: number;
    faceCentered: boolean;
  };
  gestureScores: {
    idleThreshold: number;
    activeThreshold: number;
    verticalActiveThreshold: number;
    upScoreThreshold: number;
    downScoreThreshold: number;
  };
  videoSource: {
    active: boolean;
  };
};

export interface IDetectionStore {
  rangeFiltering: {
    boundingBox: BoundingBox | undefined;
    onRange: boolean;
  };
  faceLandMark: {
    detector: FaceLandmarker | undefined;
    complete: boolean;
    result: FaceLandmarkerResult | undefined;
    canvasRef: React.RefObject<HTMLCanvasElement> | undefined;
  };
  orientation?: TOrientation;
  webcam: {
    videoElement: HTMLVideoElement | undefined;
    webcamRef: React.RefObject<Webcam> | undefined;
    webcamStream: MediaProvider | undefined;
    isWebcamOn: boolean;
  };
  gestures: {
    scores: TGesstureScores;
    idle: TGestureScoresFlag;
    active: TGestureScoresFlag;
    last: TGesstureScores;
  };
  benchmark: {
    PPS: number;
    FPS: number;
    CPS: number;
  };
  onRange: boolean;
  paused: boolean;
  config: TDetectionConfig;
  setWebcamStream: (stream: MediaProvider | undefined) => void;
  setIsWebcamOn: (isWebcamOn: boolean) => void;
  setWebcamRef: (webcamRef: React.RefObject<Webcam>) => void;
  setRangeFilter: (boundingBox: BoundingBox | undefined, onRange: boolean) => void;
  setFaceLandMarkerDetector: (detector: FaceLandmarker) => void;
  setVideoElement: (video: HTMLVideoElement) => void;
  setFaceLandMarkerDetectorComplete: (complete: boolean) => void;
  setFaceLandMarkerDetectorResults: (result: FaceLandmarkerResult) => void;
  setGestureScores: (scores: TGesstureScores, last: TGesstureScores, idle: TGestureScoresFlag, active: TGestureScoresFlag) => void;
  setPaused: (paused: boolean) => void;
  setBenchmark: (benchmark: { PPS?: number; FPS?: number; CPS?: number }) => void;
  setOrientation: (orientation: TOrientation) => void;
}

const useDetectionStore = create<IDetectionStore>(set => ({
  rangeFiltering: {
    boundingBox: undefined,
    onRange: false,
  },
  orientation: window.innerWidth > window.innerHeight ? 'LANDSCAPE' : 'PORTRAIT',
  benchmark: {
    PPS: 0,
    FPS: 0,
    CPS: 0,
  },
  faceLandMark: {
    result: undefined,
    complete: false,
    detector: undefined,
    canvasRef: undefined,
  },
  webcam: {
    videoElement: undefined,
    webcamRef: undefined,
    webcamStream: undefined,
    isWebcamOn: false,
  },
  gestures: {
    scores: {
      ...defaultGestureScores,
    },
    idle: {
      ...defaultGestureScoresFlag,
    },
    active: {
      ...defaultGestureScoresFlag,
    },
    last: {
      ...defaultGestureScores,
    },
  },

  config: {
    faceLandMark: {
      active: false,
      faceLandmarkerOptions: {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
          // modelAssetPath: `wasm/face_landmarker.task`,
          delegate: 'GPU',
        },
        outputFaceBlendshapes: true,
        runningMode: 'VIDEO',
        numFaces: 1,
      },
      // wasmFileBasePath: 'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm',
      wasmFileBasePath: 'wasm',
    },
    rangeFiltering: {
      active: false,
      faceWidthMin: 0.2,
      faceWidthMax: 0.6,
      faceCentered: false,
    },
    gestureScores: {
      idleThreshold: 0.3,
      activeThreshold: 0.6,
      verticalActiveThreshold: 0.5,
      upScoreThreshold: 0.6,
      downScoreThreshold: 0.6,
    },
    videoSource: {
      active: true,
    },
  },
  onRange: false,
  paused: false,

  setRangeFilter: (boundingBox: BoundingBox | undefined, onRange: boolean) =>
    set(state => ({
      ...state,
      rangeFiltering: {
        ...state.rangeFiltering,
        boundingBox,
        onRange,
      },
    })),
  setFaceLandMarkerDetector: (detector: FaceLandmarker) =>
    set(state => ({
      ...state,
      faceLandMark: {
        ...state.faceLandMark,
        detector,
        complete: true,
      },
    })),
  setVideoElement: (video: HTMLVideoElement) =>
    set(state => ({
      ...state,
      webcam: {
        ...state.webcam,
        videoElement: video,
      },
    })),
  setFaceLandMarkerDetectorComplete: (complete: boolean) =>
    set(state => ({
      ...state,
      faceLandMark: {
        ...state.faceLandMark,
        complete,
      },
    })),
  setFaceLandMarkerDetectorResults: (result: FaceLandmarkerResult) =>
    set(state => ({
      ...state,
      faceLandMark: {
        ...state.faceLandMark,
        result,
      },
    })),
  setGestureScores: (scores: TGesstureScores, last: TGesstureScores, idle: TGestureScoresFlag, active: TGestureScoresFlag) =>
    set(state => ({
      ...state,
      gestures: {
        scores,
        idle,
        active,
        last,
      },
    })),
  setPaused: paused => set(state => ({ ...state, paused })),
  setWebcamStream: (stream: MediaProvider | undefined) =>
    set(state => ({
      ...state,
      webcam: { ...state.webcam, webcamStream: stream },
    })),
  setIsWebcamOn: (isWebcamOn: boolean) => set(state => ({ ...state, webcam: { ...state.webcam, isWebcamOn } })),
  setWebcamRef: (webcamRef: React.RefObject<Webcam>) => set(state => ({ ...state, webcam: { ...state.webcam, webcamRef } })),
  setBenchmark: (benchmark: { PPS?: number; FPS?: number; CPS?: number }) => set(state => ({ ...state, benchmark: { ...state.benchmark, ...benchmark } })),
  setOrientation: (orientation: TOrientation) => set(state => ({ ...state, orientation })),
}));

export default useDetectionStore;
