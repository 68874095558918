import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const PreloaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid #2a2a2a;
  border-top: 5px solid #007bff;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const Message = styled.p`
  color: white;
  margin-top: 20px;
  font-size: 1.1rem;
`;

interface PreloaderProps {
  message: string;
}

const Preloader: React.FC<PreloaderProps> = ({ message }) => {
  return (
    <PreloaderContainer>
      <Spinner />
      <Message>{message}</Message>
    </PreloaderContainer>
  );
};

export default Preloader;
