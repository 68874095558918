import styled from 'styled-components';
import { device } from '@/Layout/breakpoints';

export const HelperContainer = styled.div`
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 9999;
  font-family: monospace;
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${device.mobileLandscape} {
    font-size: 10px;
    padding: 6px 8px;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  span {
    &:first-child {
      color: #66ff66;
    }
  }
`;
