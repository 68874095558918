import React, { useEffect, useCallback, useMemo } from 'react';
import Gestureflag from '..';
import waveform from '@/Assets/icons/waveform.svg';
import volumeUp from '@/Assets/icons/volume-up.svg';
import { useGestureConfirmationStore } from '@/Models/useGestureConfirmationStore';
import useGestureKeyboardStore from '@/Models/useGestureKeyboardStore';
import useTextToSpeech from '@/Services/useTextToSpeech';
import { SpeakWrapper, SpeakText, GestureFlagWrapper } from './Speak.styles';
import { KEYBOARD_TYPE } from '@/Models/Constants';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { EYE_GESTURE_EVENT } from '@/Models/Constants';
import useDetectionStore from '@/Models/useDetectionStore';

const Speak: React.FC = React.memo(function Speak() {
  const { confirmation, config } = useGestureConfirmationStore();
  const {
    user: {
      settings: { selectedKeyboard, selectedKeyboardSettings },
    },
  } = useUserSessionStore();
  const { keyboardSpeakGesture } = selectedKeyboardSettings;
  const { currentGesture, phrase } = useGestureKeyboardStore();
  const { isSynthesizing, handleSynthesis } = useTextToSpeech();
  const {
    gestures: { active },
  } = useDetectionStore();

  const { HORIZONTAL_SPLIT_SELECTION, VERTICAL_SPLIT_SELECTION, HORIZONTAL_TRACKING_MOUSE } = KEYBOARD_TYPE;

  // Memoize the check for valid keyboard types
  const isValidKeyboardType = useMemo(() => {
    return selectedKeyboard === VERTICAL_SPLIT_SELECTION || selectedKeyboard === HORIZONTAL_SPLIT_SELECTION;
  }, [selectedKeyboard, VERTICAL_SPLIT_SELECTION, HORIZONTAL_SPLIT_SELECTION]);

  // Memoize handleClick to prevent recreation on every render
  const handleClick = useCallback(() => {
    if (selectedKeyboard === HORIZONTAL_TRACKING_MOUSE) {
      handleSynthesis(phrase);
    }
  }, [selectedKeyboard, handleSynthesis, phrase, HORIZONTAL_TRACKING_MOUSE]);

  useEffect(() => {
    if (!isSynthesizing && isValidKeyboardType && currentGesture === keyboardSpeakGesture) {
      console.log('[speak] [gesture] ', phrase, keyboardSpeakGesture);
      handleSynthesis(phrase);
    }
  }, [currentGesture, isSynthesizing, isValidKeyboardType, keyboardSpeakGesture, phrase, handleSynthesis]);

  // Memoize the gesture configuration values
  const gestureConfig = useMemo(() => {
    return {
      startTime: confirmation.startTime[keyboardSpeakGesture as EYE_GESTURE_EVENT],
      timeout: config.timeout[keyboardSpeakGesture as EYE_GESTURE_EVENT],
    };
  }, [confirmation.startTime, config.timeout, keyboardSpeakGesture]);

  // Memoize the icon selection
  const iconUrl = useMemo(() => (isSynthesizing ? volumeUp : waveform), [isSynthesizing]);

  return (
    <SpeakWrapper id="speak-feedback" $running={isSynthesizing} $keyboardType={selectedKeyboard} onClick={handleClick}>
      <GestureFlagWrapper>
        <Gestureflag active={active[keyboardSpeakGesture as EYE_GESTURE_EVENT]} svgUrl={iconUrl} startTime={gestureConfig.startTime} timeout={gestureConfig.timeout} fill={isSynthesizing} />
      </GestureFlagWrapper>
      <SpeakText $keyboardType={selectedKeyboard}>PRONUNCIAR</SpeakText>
    </SpeakWrapper>
  );
});

// Añadir displayName para mejor debugging
Speak.displayName = 'Speak';

export default Speak;
