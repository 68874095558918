import { create } from 'zustand';
import { TGestureScoresFlag, TGesstureScores, defaultGestureScoresFlag, defaultGestureScores } from '@/Models/Types';

export type TGestureConfirmation = {
  completed: TGestureScoresFlag;
  running: TGestureScoresFlag;
  startTime: TGesstureScores;
};

export interface IGestureConfirmationStore {
  setConfirmation: (confirmation: TGestureConfirmation) => void;
  confirmation: TGestureConfirmation;
  config: {
    timeout: TGesstureScores;
  };

  setCompleted: (completed: TGestureScoresFlag) => void;
}

export const useGestureConfirmationStore = create<IGestureConfirmationStore>(set => ({
  confirmation: {
    completed: { ...defaultGestureScoresFlag },
    running: { ...defaultGestureScoresFlag },
    startTime: { ...defaultGestureScores },
  },

  config: {
    timeout: {
      ...defaultGestureScores,
      look_up: 1000,
      lookDownScore: 1000,
      look_left: 750,
      look_right: 750,
      lookIdleScore: 2000,
    },
  },

  setConfirmation: confirmation => set(() => ({ confirmation })),
  setCompleted: (completed: TGestureScoresFlag) => set(state => ({ confirmation: { ...state.confirmation, completed } })),
}));
