import { memo } from 'react';
import Cursor from '@/Components/Cursor/Cursor';
import { IKeyboardGestureStore, useGestureKeyboardStore } from '@/Models/useGestureKeyboardStore';
import { IUseTextToSpeechStore, useTextToSpeechStore } from '@/Models/useTextToSpeechStore';
import useUserSessionStore, { IUserSessionState } from '@/Models/useUserSessionStore';
import { KEYBOARD_TYPE } from '@/Models/Constants';
import { StyledContainer, PhraseText } from './PhraseContainer.styles';

const { VERTICAL_SPLIT_SELECTION, HORIZONTAL_SPLIT_SELECTION } = KEYBOARD_TYPE;

// Separate selectors for each piece of state
const selectPhrase = (state: IKeyboardGestureStore) => state.phrase;
const selectKeyboardSettings = (state: IUserSessionState) => ({
  selectedKeyboard: state.user.settings.selectedKeyboard,
  selectedKeyboardSettings: state.user.settings.selectedKeyboardSettings,
});
const selectIsSynthesizing = (state: IUseTextToSpeechStore) => state.isSynthesizing;

const PhraseContainer = () => {
  // Use specific selectors to only subscribe to needed state changes
  const phrase = useGestureKeyboardStore(selectPhrase);
  const { selectedKeyboard, selectedKeyboardSettings } = useUserSessionStore(selectKeyboardSettings);
  const isSynthesizing = useTextToSpeechStore(selectIsSynthesizing);

  if (!selectedKeyboardSettings) {
    return null;
  }

  const isVerticalLayout = selectedKeyboard === VERTICAL_SPLIT_SELECTION || selectedKeyboard === HORIZONTAL_SPLIT_SELECTION;

  return (
    <StyledContainer $isVertical={isVerticalLayout} $isSynthesizing={isSynthesizing}>
      <PhraseText $fontSize={selectedKeyboardSettings.phraseFontSize} $isSynthesizing={isSynthesizing}>
        {phrase === '' ? 'Escribe un mensaje con tus ojos...' : phrase}
      </PhraseText>
      <Cursor />
    </StyledContainer>
  );
};

export default memo(PhraseContainer);
