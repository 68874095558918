import React from 'react';
import { EYE_GESTURE_EVENT } from '@/Models/Constants';
import Gestureflag from '@/Components/GestureFlag';
import check from '@/Assets/icons/check.svg';
import useDetectionStore from '@/Models/useDetectionStore';
import { useGestureConfirmationStore } from '@/Models/useGestureConfirmationStore';
import styled from 'styled-components';

const FeedbackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const StyledGestureFlag = styled(Gestureflag)`
  transform: scale(0.8);
`;

interface ConfirmationFeedbackProps {
  gesture: EYE_GESTURE_EVENT;
}

const ConfirmationFeedback: React.FC<ConfirmationFeedbackProps> = ({ gesture }) => {
  const startTime = useGestureConfirmationStore(state => state.confirmation.startTime[gesture]);
  const timeout = useGestureConfirmationStore(state => state.config.timeout[gesture]);
  const active = useDetectionStore(state => state.gestures.active[gesture]);

  return <FeedbackContainer>{active && <StyledGestureFlag active={active} svgUrl={check} startTime={startTime} timeout={timeout} />}</FeedbackContainer>;
};

export default ConfirmationFeedback;
