/**
 * Enum de comandos disponibles.
 */
export enum CommandsTypes {
  Restart = 'Reiniciar',
  Space = 'Espacio',
  Enter = 'Enter',
  Delete = 'Borrar',
  DeleteAll = 'Borrar Todo',
}

/**
 * Enum de comandos disponibles.
 */
export enum COMMANDS {
  DELETE = 'BORRAR',
  DELETE_ALL = 'BORRAR TODO',
  SPACE = 'ESPACIO',
  SPEAK = 'PRONUNCIAR',
  RESET = 'REINICIAR',
}

/**
 * Lista de teclas disponibles.
 */
export const KEY_LIST = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  '.',
  ',',
];

/**
 * Lista de comandos disponibles.
 */

export const LEFT_COMMANDS = [COMMANDS.DELETE, COMMANDS.DELETE_ALL];
export const RIGHT_COMMANDS = [COMMANDS.SPACE, COMMANDS.SPEAK, COMMANDS.RESET];

/**
 * Interfaz que representa un registro de gesto.
 */
export interface IGestureRecord {
  gesture: EYE_GESTURE_EVENT;
  timeStamp: string;
}

/**
 * Default font sizes for keyboard and phrase
 */
export const DEFAULT_FONT_SIZES = {
  KEY_FONT_SIZE: 32,
  PHRASE_FONT_SIZE: 32,
} as const;

/**
 * Enum de eventos de gestos del teclado.
 */
export enum EYE_GESTURE_EVENT {
  LOOK_LEFT = 'look_left',
  LOOK_RIGHT = 'look_right',
  LOOK_UP = 'look_up',
  LOOK_DOWN = 'look_down',
  BLINK = 'blink',
}

/**
 * Etiquetas de los gestos del teclado.
 */
export const GestureLabels: Record<EYE_GESTURE_EVENT, string> = {
  [EYE_GESTURE_EVENT.LOOK_LEFT]: 'Mirar Izquierda',
  [EYE_GESTURE_EVENT.LOOK_RIGHT]: 'Mirar Derecha',
  [EYE_GESTURE_EVENT.LOOK_UP]: 'Mirar Arriba',
  [EYE_GESTURE_EVENT.LOOK_DOWN]: 'Mirar Abajo',
  [EYE_GESTURE_EVENT.BLINK]: 'Pestañear',
};

export enum KEYBOARD_TYPE {
  VERTICAL_SPLIT_SELECTION = 'VERTICAL_SPLIT_SELECTION',
  HORIZONTAL_SPLIT_SELECTION = 'HORIZONTAL_SPLIT_SELECTION',
  HORIZONTAL_TRACKING_MOUSE = 'HORIZONTAL_TRACKING_MOUSE',
}

export const KeyboardTypeLabels: Record<KEYBOARD_TYPE, string> = {
  [KEYBOARD_TYPE.VERTICAL_SPLIT_SELECTION]: 'Teclado Dividido Vertical',
  [KEYBOARD_TYPE.HORIZONTAL_SPLIT_SELECTION]: 'Teclado Dividido Horizontal',
  [KEYBOARD_TYPE.HORIZONTAL_TRACKING_MOUSE]: 'Teclado con reconocimiento de Rostro',
};

/**
 * Mapeo de gestos permitidos para cada tipo de teclado
 */
export const KEYBOARD_AVAILABLE_SPEAK_GESTURES: Record<KEYBOARD_TYPE, EYE_GESTURE_EVENT[]> = {
  [KEYBOARD_TYPE.VERTICAL_SPLIT_SELECTION]: [EYE_GESTURE_EVENT.BLINK],
  [KEYBOARD_TYPE.HORIZONTAL_SPLIT_SELECTION]: [EYE_GESTURE_EVENT.LOOK_UP, EYE_GESTURE_EVENT.LOOK_DOWN, EYE_GESTURE_EVENT.BLINK],
  [KEYBOARD_TYPE.HORIZONTAL_TRACKING_MOUSE]: [],
};

export const EYE_DETECTION_SOURCES = {
  LEFT_EYE: 'leftEye',
  RIGHT_EYE: 'rightEye',
} as const;

export type TEyeDetectionSource = (typeof EYE_DETECTION_SOURCES)[keyof typeof EYE_DETECTION_SOURCES];

export const THRESHOLD_SLIDER_STEP = 0.1;

export enum EyeGestures {
  EyeBlinkLeft = 'eyeBlinkLeft',
  EyeBlinkRight = 'eyeBlinkRight',
  EyeLookDownLeft = 'eyeLookDownLeft',
  EyeLookDownRight = 'eyeLookDownRight',
  EyeLookInLeft = 'eyeLookInLeft',
  EyeLookInRight = 'eyeLookInRight',
  EyeLookOutLeft = 'eyeLookOutLeft',
  EyeLookOutRight = 'eyeLookOutRight',
  EyeLookUpLeft = 'eyeLookUpLeft',
  EyeLookUpRight = 'eyeLookUpRight',
  EyeSquintLeft = 'eyeSquintLeft',
  EyeSquintRight = 'eyeSquintRight',

  Neutral = '_neutral',
}
