import React from 'react';
import { ReactComponent as EyeActive } from '@/Assets/icons/eye-active.svg';
import { ReactComponent as EyeInactive } from '@/Assets/icons/eye-inactive.svg';
import { ButtonIcon } from '@/Components/Shared/ButtonIcon';
import { EyeLabel, EyeStatus, StyledEyeControl } from './EyeStatusIndicator';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { EYE_DETECTION_SOURCES, TEyeDetectionSource } from '@/Models/Constants';

const { LEFT_EYE } = EYE_DETECTION_SOURCES;

interface EyeControlProps {
  side: TEyeDetectionSource;
}

export const EyeControl: React.FC<EyeControlProps> = ({ side }) => {
  const {
    setEyeSource,
    user: {
      settings: {
        selectedKeyboardSettings: { eyeSource },
      },
    },
  } = useUserSessionStore();

  const onToggle = () => {
    const otherEye = side === LEFT_EYE ? 'rightEye' : 'leftEye';
    // Si el otro ojo está desactivado y este se va a desactivar, no permitir el cambio
    if (!eyeSource[otherEye] && eyeSource[side]) {
      return;
    }

    setEyeSource({
      ...eyeSource,
      [side]: !eyeSource[side],
    });
  };

  return (
    <StyledEyeControl>
      <EyeStatus onClick={onToggle}>
        <ButtonIcon as={eyeSource[side] ? EyeActive : EyeInactive} alt={`${side} eye ${eyeSource[side] ? 'active' : 'inactive'}`} />
        <EyeLabel>
          Ojo {side === LEFT_EYE ? 'izquierdo' : 'derecho'} {eyeSource[side] ? 'activo' : 'inactivo'}
        </EyeLabel>
      </EyeStatus>
    </StyledEyeControl>
  );
};

export default EyeControl;
