import React from 'react';
import KeyboardTypeSelector from '@/Components/ConfigModal/Section/KeyboardTypeSelector';
import SpeakGestureSelector from '@/Components/ConfigModal/Section/SpeakGestureSelector';
import DetectionControl from '@/Components/ConfigModal/Section/DetectionControl';
import FontSizeControl from '@/Components/ConfigModal/Section/FontSizeControl';
import SaveButton from '@/Components/Common/SaveButton';
import Preloader from '@/Components/Common/Preloader';
import { ModalContent, Overlay, Section, Modal, Header, CloseButton, SectionsWrapper } from '@/Components/ConfigModal/ConfigModal.styles';
import GestureControl from './Section/GestureControl/GestureControl';
import EyeStatusIndicator from './Section/EyeStatusIndicator/EyeStatusIndicator';
import useDetectionStore from '@/Models/useDetectionStore';

interface ConfigModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ConfigModal: React.FC<ConfigModalProps> = ({ isOpen, onClose }) => {
  const [isSaving, setIsSaving] = React.useState(false);

  const orientation = useDetectionStore(state => state.orientation);

  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSave = () => {
    setIsSaving(true);
    setTimeout(() => {
      setIsSaving(false);
      onClose();
    }, 2000);
  };

  return (
    <>
      {isSaving && <Preloader message="Guardando ajustes de configuración..." />}
      <Overlay onClick={handleOverlayClick} className="overlay">
        <Modal $isLandscape={orientation === 'LANDSCAPE'} className="modal">
          <Header className="header">
            <h2>Ajustes de Configuración</h2>
            <CloseButton onClick={onClose}>✕</CloseButton>
          </Header>
          <ModalContent className="modal-content" $isLandscape={orientation === 'LANDSCAPE'}>
            <SectionsWrapper className="sections-wrapper">
              <Section columns={orientation === 'LANDSCAPE' ? 3 : 1}>
                <DetectionControl />
                <KeyboardTypeSelector />
                <FontSizeControl />
              </Section>
              <Section>
                <EyeStatusIndicator />
                {/* <SpeakGestureSelector /> */}
              </Section>
              <Section>
                {/* <EyeStatusIndicator /> */}
                <SpeakGestureSelector />
              </Section>

              <Section>
                <GestureControl />
              </Section>
              <Section></Section>
              {/* 
              <Section>
              </Section> */}
            </SectionsWrapper>
          </ModalContent>
          <SaveButton onClick={handleSave}>Guardar Configuración</SaveButton>
        </Modal>
      </Overlay>
    </>
  );
};

export default ConfigModal;
