import useDetectionStore from '@/Models/useDetectionStore';
import React from 'react';

export const useOrientationCheck = () => {
  const [isLandscape, setIsLandscape] = React.useState<boolean>(window.innerWidth > window.innerHeight);
  const setOrientation = useDetectionStore(state => state.setOrientation);
  const orientation = useDetectionStore(state => state.orientation);

  React.useEffect(() => {
    const checkOrientation = () => {
      const isLandscapeNow = window.innerWidth > window.innerHeight;
      setIsLandscape(isLandscapeNow);

      // Update the user settings with the new orientation
      setOrientation(isLandscapeNow ? 'LANDSCAPE' : 'PORTRAIT');
    };

    // Initial check
    checkOrientation();

    // Add event listener
    window.addEventListener('resize', checkOrientation);
    window.addEventListener('orientationchange', checkOrientation);

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkOrientation);
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, [orientation, setOrientation]);

  return isLandscape;
};

export default useOrientationCheck;
