import React from 'react';
import logoutIcon from '@/Assets/icons/logout-svgrepo-com.svg';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { ButtonIcon } from '@/Components/Shared/ButtonIcon';
import { LogoutButtonStyled } from './LogoutButton.styles';

const LogoutButton: React.FC = () => {
  const { logout } = useUserSessionStore();

  const handleLogout = () => {
    logout();
  };

  return (
    <LogoutButtonStyled onClick={handleLogout}>
      <ButtonIcon src={logoutIcon} alt="logout icon" />
      <span>Cerrar Sesión</span>
    </LogoutButtonStyled>
  );
};

export default LogoutButton;
