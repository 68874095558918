import React, { useCallback, useMemo } from 'react';
import logoutIcon from '@/Assets/icons/logout-svgrepo-com.svg';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { ButtonIcon } from '@/Components/Shared/ButtonIcon';
import { SpeakButtonStyled } from './SpeakButton.styles';
import Speak from '@/Components/GestureFlag/Speak/Speak';
import waveform from '@/Assets/icons/waveform.svg';
import volumeUp from '@/Assets/icons/volume-up.svg';
import useTextToSpeech from '@/Services/useTextToSpeech';
import Gestureflag from '@/Components/GestureFlag';
import { EYE_GESTURE_EVENT, KEYBOARD_TYPE } from '@/Models/Constants';
import useDetectionStore from '@/Models/useDetectionStore';
import useGestureKeyboardStore from '@/Models/useGestureKeyboardStore';
import { useGestureConfirmationStore } from '@/Models/useGestureConfirmationStore';

const SpeakButton: React.FC = () => {
  const { isSynthesizing, handleSynthesis } = useTextToSpeech();
  const active = useDetectionStore(state => state.gestures.active);
  const keyboardSpeakGesture = useUserSessionStore(state => state.user.settings.selectedKeyboardSettings.keyboardSpeakGesture);
  const selectedKeyboard = useUserSessionStore(state => state.user.settings.selectedKeyboard);
  const phrase = useGestureKeyboardStore(state => state.phrase);
  const { confirmation, config } = useGestureConfirmationStore();

  const iconUrl = useMemo(() => (isSynthesizing ? volumeUp : waveform), [isSynthesizing]);

  const gestureConfig = useMemo(
    () => ({
      startTime: confirmation.startTime[keyboardSpeakGesture as EYE_GESTURE_EVENT],
      timeout: config.timeout[keyboardSpeakGesture as EYE_GESTURE_EVENT],
    }),
    [confirmation.startTime, config.timeout, keyboardSpeakGesture]
  );

  // Memoize handleClick to prevent recreation on every render
  const handleClick = useCallback(() => {
    if (selectedKeyboard === KEYBOARD_TYPE.HORIZONTAL_TRACKING_MOUSE) {
      handleSynthesis(phrase);
    }
  }, [selectedKeyboard, handleSynthesis, phrase]);

  return (
    <SpeakButtonStyled onClick={handleClick}>
      <ButtonIcon src={logoutIcon} alt="logout icon" />
      <Gestureflag active={active[keyboardSpeakGesture as EYE_GESTURE_EVENT]} svgUrl={iconUrl} startTime={gestureConfig.startTime} timeout={gestureConfig.timeout} fill={isSynthesizing} />
      <Speak />
      <span>Pronunciar</span>
    </SpeakButtonStyled>
  );
};

export default SpeakButton;
