import styled from 'styled-components';
import { BaseButton } from '../Shared/ButtonStyles';
import { device } from '@/Layout/breakpoints';

export const SpeakButtonStyled = styled(BaseButton)`
  /* outline: none;  */
  ${device.mobileAll} {
    outline: none;
    span {
      display: none;
    }
  }
`;
