import { EYE_GESTURE_EVENT } from '@/Models/Constants';
import Gestureflag, { GestureflagProps } from '..';
import check from '@/Assets/icons/check.svg';
import useDetectionStore from '@/Models/useDetectionStore';
import { useGestureConfirmationStore } from '@/Models/useGestureConfirmationStore';
import { styled } from 'styled-components';

const StyledGestureFlag = styled(Gestureflag)`
  &.check {
    position: absolute !important;
    top: calc(20vh - 20px);
    left: 50%;
    transform: translate(-50%, -10%);
  }

  /* Selector contextual para el teclado vertical */
  :where(#vertical-keyboard) & {
    &.check {
      position: absolute;
      top: calc(50% - 50px);
      left: 50%;
      transform: translate(-50%, -10%);
    }
  }
`;

type IGestureflagProps = {
  gesture: EYE_GESTURE_EVENT;
};

const Check = function ({ gesture }: IGestureflagProps) {
  const startTime = useGestureConfirmationStore(state => state.confirmation.startTime[gesture]);
  const timeout = useGestureConfirmationStore(state => state.config.timeout[gesture]);
  const active = useDetectionStore(state => state.gestures.active[gesture]);

  const props: GestureflagProps = {
    className: 'check',
    active: active,
    svgUrl: check,
    startTime: startTime,
    timeout: timeout,
  };

  return <>{active ? <StyledGestureFlag {...props} /> : null}</>;
};

export default Check;
