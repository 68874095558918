import React, { useEffect } from 'react';
import { EYE_GESTURE_EVENT } from '@/Models/Constants';
import useGestureKeyboardStore from '@/Models/useGestureKeyboardStore';
import Check from '@/Components/GestureFlag/Check/Check';
import useDetectionStore from '@/Models/useDetectionStore';
import PhraseContainer from '@/Components/PhraseContainer/PhraseContainer';
import Speak from '@/Components/GestureFlag/Speak/Speak';
import { KeyboardKey } from '../components/KeyboardKey/KeyboardKey';
import { KeyboardContainer, KeyboardContent, KeyboardSections, KeyboardSection, KeyboardKeys, SectionSeparator } from './styles';

const { LOOK_UP, LOOK_DOWN } = EYE_GESTURE_EVENT;

export const KeyboardVerticalSplit: React.FC = () => {
  const { left, right, currentGesture, init, pick } = useGestureKeyboardStore();

  const {
    gestures: { scores },
    rangeFiltering: { onRange },
    config: {
      gestureScores: { idleThreshold },
    },
  } = useDetectionStore();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (currentGesture === LOOK_UP) {
      pickTop();
    } else if (currentGesture === LOOK_DOWN) {
      pickBottom();
    }
  }, [currentGesture]);

  const pickTop = () => {
    pick(left.keys, left.commands);
  };

  const pickBottom = () => {
    pick(right.keys, right.commands);
  };

  const isTopActive = scores.look_up > idleThreshold;
  const isTopIdle = scores.look_up > idleThreshold || !onRange;
  const isBottomActive = scores.look_down > idleThreshold;
  const isBottomIdle = scores.look_down > idleThreshold || !onRange;

  const reversedRightSideKeys = [...right.keys];
  const topSideKeys = left.commands.concat(left.keys);
  const bottomSideKeys = right.commands.concat(reversedRightSideKeys);

  return (
    <KeyboardContainer id="vertical-keyboard">
      <KeyboardContent>
        <KeyboardSections>
          <KeyboardSection className="top" $isactive={isTopActive} $isidle={isTopIdle}>
            <KeyboardKeys>
              <Check gesture={LOOK_UP} />
              {topSideKeys.map((key: string) => (
                <KeyboardKey key={key} value={key} />
              ))}
            </KeyboardKeys>
          </KeyboardSection>

          <SectionSeparator>
            <PhraseContainer />
            <Speak />
          </SectionSeparator>

          <KeyboardSection className="bottom" $isactive={isBottomActive} $isidle={isBottomIdle}>
            <KeyboardKeys>
              <Check gesture={LOOK_DOWN} />
              {bottomSideKeys.map((key: string) => (
                <KeyboardKey key={key} value={key} />
              ))}
            </KeyboardKeys>
          </KeyboardSection>
        </KeyboardSections>
      </KeyboardContent>
    </KeyboardContainer>
  );
};
