import styled from 'styled-components';

export const KeyboardContainer = styled.div`
  width: calc(100% - 20px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;
  height: 100%;
`;

export const KeyboardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const KeyboardHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 13px;

  h1 {
    color: white;
    font-size: 25px;
    font-weight: semi-bold;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 20px;
    padding: 20px 2px 20px 20px;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.605);
  }

  .cursor {
    animation: blink 1s infinite;
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 2px;
    font-size: 25px;
    color: white;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.605);
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
`;

export const KeyboardSections = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 15vh 1fr;
  gap: 10px;
  grid-template-areas:
    'top'
    'middle'
    'bottom';
  border-radius: 13px;
  position: relative;
  width: 100%;

  &.pointer {
    grid-template-columns: 1fr 5vw 1fr;
    gap: 0;
  }
`;

export const KeyboardSection = styled.div<{ $isactive: boolean; $isidle: boolean }>`
  position: relative;
  opacity: ${({ $isidle }) => ($isidle ? 0.7 : 1)};
  transition: all 0.3s ease;

  &.top {
    grid-area: top;
  }

  &.bottom {
    grid-area: bottom;
  }

  outline: 1px solid red;

  ${({ $isactive }) =>
    $isactive &&
    `
    .keyboard-key {
      outline: 1px solid rgba(255, 255, 255, 0.469);
      color: rgb(227, 227, 227);
      background-color: rgba(0, 0, 0, 1);
    }
  `}

  ${({ $isidle }) =>
    $isidle &&
    `
    .keyboard-key {
      opacity: 0.7;
      color: rgba(217, 217, 217, 0.473);
    }
  `}
`;

export const KeyboardKeys = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: start;
  border-radius: 13px;
  columns: 4;
  position: relative;
`;

export const KeyboardKey = styled.div<{ $isChar?: boolean; $isPhrase?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: wrap;
  outline: 1px solid rgba(0, 0, 0, 0.131);
  border-radius: 13px;
  color: rgb(227, 227, 227);
  background-color: rgba(0, 0, 0, 0.629);
  font-size: ${({ $isChar }) => ($isChar ? '2em' : '1em')};
  width: ${({ $isPhrase }) => ($isPhrase ? 'auto' : '8%')};
  max-width: ${({ $isPhrase }) => ($isPhrase ? '100%' : 'none')};
  height: 10vh !important;
  padding: 0 10px;
  margin: 5px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  line-height: 25px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  text-shadow: 2px 2px 4px rgb(0, 0, 0);
  text-transform: uppercase;
  white-space: pre-line;
  word-break: normal;
  overflow: ${({ $isPhrase }) => ($isPhrase ? 'hidden' : 'visible')};
  text-overflow: ${({ $isPhrase }) => ($isPhrase ? 'ellipsis' : 'clip')};

  .pointer & {
    width: 20%;
    margin: 4px;
  }
`;

export const SectionSeparator = styled.div`
  grid-area: middle;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const SectionLabel = styled.div`
  font-size: 35px;
  color: var(--color-font-secondary);
  height: 2em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;

  .bottom & {
    justify-content: center;
  }
`;
