import useGestureKeyboardStore from './useGestureKeyboardStore';
import { persist, createJSONStorage } from 'zustand/middleware';
import { DEFAULT_FONT_SIZES } from '@/Models/Constants';
import { IUserSettings, IUser, IKeyboardSettings, defaultEyeDetectionSource, defaultEyeDetectionSettings, IEyeDetectionSource, IGestureSettings } from './Types';
import { KEYBOARD_TYPE, EYE_GESTURE_EVENT } from '@/Models/Constants';
import { create } from 'zustand';
// import IUser from './Types';

const { HORIZONTAL_SPLIT_SELECTION, VERTICAL_SPLIT_SELECTION, HORIZONTAL_TRACKING_MOUSE } = KEYBOARD_TYPE;
const { LOOK_UP, BLINK } = EYE_GESTURE_EVENT;

const initialUserState: IUser = {
  authToken: '',
  role: '',
  name: '',
  email: '',
  birthdate: '',
  id: '',
  settings: {
    version: -1,
    selectedKeyboard: HORIZONTAL_SPLIT_SELECTION,
    selectedKeyboardSettings: {
      keyboardSpeakGesture: LOOK_UP,
      keyFontSize: DEFAULT_FONT_SIZES.KEY_FONT_SIZE,
      phraseFontSize: DEFAULT_FONT_SIZES.PHRASE_FONT_SIZE,
      eyeSource: { ...defaultEyeDetectionSource },
      gestureDetection: { ...defaultEyeDetectionSettings },
    },
    [HORIZONTAL_SPLIT_SELECTION]: {
      keyboardSpeakGesture: LOOK_UP,
      keyFontSize: DEFAULT_FONT_SIZES.KEY_FONT_SIZE,
      phraseFontSize: DEFAULT_FONT_SIZES.PHRASE_FONT_SIZE,
      eyeSource: { ...defaultEyeDetectionSource },
      gestureDetection: { ...defaultEyeDetectionSettings },
    },
    [VERTICAL_SPLIT_SELECTION]: {
      keyboardSpeakGesture: BLINK,
      keyFontSize: DEFAULT_FONT_SIZES.KEY_FONT_SIZE,
      phraseFontSize: DEFAULT_FONT_SIZES.PHRASE_FONT_SIZE,
      eyeSource: { ...defaultEyeDetectionSource },
      gestureDetection: { ...defaultEyeDetectionSettings },
    },
    [HORIZONTAL_TRACKING_MOUSE]: {
      keyboardSpeakGesture: LOOK_UP,
      keyFontSize: DEFAULT_FONT_SIZES.KEY_FONT_SIZE,
      phraseFontSize: DEFAULT_FONT_SIZES.PHRASE_FONT_SIZE,
      eyeSource: { ...defaultEyeDetectionSource },
      gestureDetection: { ...defaultEyeDetectionSettings },
    },
  },
};

const resetAllotherStores = () => {
  useGestureKeyboardStore.getState().restore();
};

const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen().catch(e => console.error(e));
  }
};

const releaseWebcam = () => {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(stream => {
        console.log('[STATUS] releaseWebcam', stream);
        stream.getTracks().forEach(track => track.stop());
      })
      .catch(err => console.error('Error releasing webcam:', err));
  }
};

const validateAndRepairSettings = (settings: IUserSettings): IUserSettings => {
  const defaultSettings = initialUserState.settings;

  // Validate version
  if (typeof settings.version !== 'number') {
    settings.version = defaultSettings.version;
  }

  // Validate selected keyboard
  if (!Object.values(KEYBOARD_TYPE).includes(settings.selectedKeyboard)) {
    settings.selectedKeyboard = defaultSettings.selectedKeyboard;
  }

  // Helper function to validate keyboard settings
  const validateKeyboardSettings = (keyboardSettings: IKeyboardSettings): IKeyboardSettings => {
    const defaultKbSettings = defaultSettings[settings.selectedKeyboard];
    return {
      keyboardSpeakGesture: Object.values(EYE_GESTURE_EVENT).includes(keyboardSettings?.keyboardSpeakGesture) ? keyboardSettings.keyboardSpeakGesture : defaultKbSettings.keyboardSpeakGesture,
      keyFontSize: typeof keyboardSettings?.keyFontSize === 'number' ? keyboardSettings.keyFontSize : defaultKbSettings.keyFontSize,
      phraseFontSize: typeof keyboardSettings?.phraseFontSize === 'number' ? keyboardSettings.phraseFontSize : defaultKbSettings.phraseFontSize,
      eyeSource: {
        leftEye: typeof keyboardSettings?.eyeSource?.leftEye === 'number' ? keyboardSettings.eyeSource.leftEye : defaultKbSettings.eyeSource.leftEye,
        rightEye: typeof keyboardSettings?.eyeSource?.rightEye === 'number' ? keyboardSettings.eyeSource.rightEye : defaultKbSettings.eyeSource.rightEye,
      },
      gestureDetection: {
        ...defaultKbSettings.gestureDetection,
        ...(keyboardSettings?.gestureDetection || {}),
      },
    };
  };

  // Validate and repair each keyboard configuration
  Object.values(KEYBOARD_TYPE).forEach(keyboardType => {
    settings[keyboardType] = validateKeyboardSettings(settings[keyboardType] || {});
  });

  // Ensure selectedKeyboardSettings matches the current keyboard settings
  settings.selectedKeyboardSettings = settings[settings.selectedKeyboard];

  return settings;
};

const rehydrateState = async (): Promise<IUser | null> => {
  try {
    const storedState = JSON.parse(localStorage.getItem('user-session-storage') || '{}');
    if (storedState.state?.user) {
      const user = storedState.state.user;

      // Validate user basic info
      if (!user.id || !user.email) {
        console.warn('[STATUS] rehydrateState: Invalid user data, using default state');
        return null;
      }

      // Validate and repair settings if needed
      user.settings = validateAndRepairSettings(user.settings || {});

      console.log('[STATUS] rehydrateState: Successfully validated and repaired user state');
      return user;
    }
  } catch (error) {
    console.error('[STATUS] rehydrateState: Error parsing stored state', error);
  }

  console.log('[STATUS] rehydrateState: No valid state found, using default');
  return null;
};

export interface IUserSessionState {
  user: IUser;
  isAuthenticated: boolean;
  updateUserSettings: (settings: IUserSettings) => void;
  getAuthToken: () => string;
  getKeyboardType: () => KEYBOARD_TYPE;
  getSpeakGesture: () => EYE_GESTURE_EVENT;
  setUserSessionState: (user: IUser) => void;
  setKeyboardType: (type: KEYBOARD_TYPE) => void;
  setKeyboardSettings: (settings: IKeyboardSettings) => void;
  // setSpeakGesture: (gesture: EYE_GESTURE_EVENT) => void;
  setKeyFontSize: (size: number) => void;
  setPhraseFontSize: (size: number) => void;
  setEyeSource: (eyeSource: IEyeDetectionSource) => void;
  setGestureDetectionSettings: (gesture: EYE_GESTURE_EVENT, newGestureSettings: IGestureSettings) => void;
  logout: () => void;
  rehydrateState: () => Promise<void>;
}

const useUserSessionStore = create<IUserSessionState>()(
  persist(
    (set, get) => ({
      user: { ...initialUserState },
      isAuthenticated: false,
      setUserSessionState: user => {
        set({
          user,
          isAuthenticated: !!user,
        });
      },
      getAuthToken: () => {
        const user = get().user;
        return user?.authToken || '';
      },
      updateUserSettings: settings => {
        const currentUser = get().user;
        if (currentUser) {
          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                ...settings,
              },
            },
          });
        }
      },
      setKeyboardType: (type: KEYBOARD_TYPE) => {
        const currentUser = get().user;
        if (currentUser) {
          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                selectedKeyboard: type,
                selectedKeyboardSettings: currentUser.settings[type],
              },
            },
          });
        }
      },
      getKeyboardType: () => {
        const currentUser = get().user;
        return currentUser?.settings.selectedKeyboard || HORIZONTAL_SPLIT_SELECTION;
      },

      setKeyboardSettings: (settings: IKeyboardSettings) => {
        const currentUser = get().user;
        if (currentUser) {
          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                selectedKeyboardSettings: settings,
                [currentUser.settings.selectedKeyboard]: {
                  ...currentUser.settings[currentUser.settings.selectedKeyboard],
                  ...settings,
                },
              },
            },
          });
        }
      },

      // setSpeakGesture: (gesture: EYE_GESTURE_EVENT) => {
      // const currentUser = get().user;
      // if (currentUser) {
      //   set({
      //     user: {
      //       ...currentUser,
      //       settings: {
      //         ...currentUser.settings,

      //         selectedKeyboardSettings: {
      //           ...currentUser.settings.selectedKeyboardSettings,
      //           keyboardSpeakGesture: gesture,
      //         },

      //         [currentUser.settings.selectedKeyboard]: {
      //           ...currentUser.settings[currentUser.settings.selectedKeyboard],
      //           keyboardSpeakGesture: gesture,
      //         },
      //       },
      //     },
      //   });
      // }
      // },
      getSpeakGesture: () => {
        const currentUser = get().user;
        return currentUser?.settings[currentUser.settings.selectedKeyboard].keyboardSpeakGesture;
      },
      setKeyFontSize: (size: number) => {
        const currentUser = get().user;
        if (currentUser) {
          const currentKeyboard = currentUser.settings.selectedKeyboard;
          const currentSettings = currentUser.settings[currentKeyboard];

          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                selectedKeyboardSettings: {
                  ...currentSettings,
                  keyFontSize: size,
                },
                [currentKeyboard]: {
                  ...currentSettings,
                  keyFontSize: size,
                },
              },
            },
          });
        }
      },
      setPhraseFontSize: (size: number) => {
        const currentUser = get().user;
        if (currentUser) {
          const currentKeyboard = currentUser.settings.selectedKeyboard;
          const currentSettings = currentUser.settings[currentKeyboard];

          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                selectedKeyboardSettings: {
                  ...currentSettings,
                  phraseFontSize: size,
                },
                [currentKeyboard]: {
                  ...currentSettings,
                  phraseFontSize: size,
                },
              },
            },
          });
        }
      },
      setEyeSource: ({ leftEye, rightEye }) => {
        console.log('[STATUS] setEyeSource', leftEye, rightEye);
        const currentUser = get().user;
        if (currentUser) {
          const currentKeyboard = currentUser.settings.selectedKeyboard;
          const currentSettings = currentUser.settings[currentKeyboard];

          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                selectedKeyboardSettings: {
                  ...currentSettings,
                  eyeSource: {
                    ...currentSettings.eyeSource,
                    leftEye,
                    rightEye,
                  },
                },
                [currentKeyboard]: {
                  ...currentSettings,
                  eyeSource: {
                    ...currentSettings.eyeSource,
                    leftEye,
                    rightEye,
                  },
                },
              },
            },
          });
        }
      },
      setGestureDetectionSettings: (gesture: EYE_GESTURE_EVENT, newGestureSettings: IGestureSettings) => {
        console.log('[STATUS] setGestureDetectionSettings', gesture, newGestureSettings);
        const currentUser = get().user;
        if (currentUser) {
          const currentKeyboard = currentUser.settings.selectedKeyboard;
          const currentSettings = currentUser.settings[currentKeyboard];

          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                selectedKeyboardSettings: {
                  ...currentSettings,
                  gestureDetection: {
                    ...currentSettings.gestureDetection,
                    [gesture]: {
                      ...currentSettings.gestureDetection[gesture],
                      ...newGestureSettings,
                    },
                  },
                },
                [currentKeyboard]: {
                  ...currentSettings,
                  gestureDetection: {
                    ...currentSettings.gestureDetection,
                    [gesture]: {
                      ...currentSettings.gestureDetection[gesture],
                      ...newGestureSettings,
                    },
                  },
                },
              },
            },
          });
        }
      },

      logout: () => {
        set({
          user: initialUserState,
          isAuthenticated: false,
        });
        resetAllotherStores();
        exitFullscreen();
        localStorage.removeItem('user-session-storage');
        releaseWebcam();
        //refresh window
        window.location.reload();
      },
      rehydrateState: async () => {
        const user = await rehydrateState();
        if (user) {
          set({
            user,
            isAuthenticated: true,
          });
        }
      },
    }),
    {
      name: 'user-session-storage',
      storage: createJSONStorage(() => localStorage),
      skipHydration: true,
    }
  )
);

export default useUserSessionStore;
