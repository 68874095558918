import styled from 'styled-components';
import { BaseButton } from '../Shared/ButtonStyles';
import { device } from '@/Layout/breakpoints';

export const FullScreenButtonStyled = styled(BaseButton)`
  ${device.mobileLandscape} {
    padding: 1em;
    aspect-ratio: 1;
    justify-content: center;
    /* background-color: red; */

    /* span {
      display: none;
    } */

    svg {
      margin: 0;
    }
  }

  ${device.mobilePortrait} {
    /* padding: 1vh; */
    /* background-color: pink; */
  }
`;
