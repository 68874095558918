import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 12px 24px;
  margin: 26px 13px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  width: auto;
  margin-top: 20px;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  &:disabled {
    background-color: #2a2a2a;
    cursor: not-allowed;
  }
`;

interface SaveButtonProps {
  onClick: () => void;
  disabled?: boolean;
  children: React.ReactNode;
}

const SaveButton: React.FC<SaveButtonProps> = ({ onClick, disabled, children }) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled}>
      {children}
    </StyledButton>
  );
};

export default SaveButton;
