import { KeyboardWithTracking } from './KeyboardWithTracking/KeyboardWithTracking';
import { KeyboardVerticalSplit } from './KeyboardVerticalSplit/KeyboardVerticalSplit';
import { KeyboardHorizontalSplit } from './KeyboardHorizontalSplit/KeyboardHorizontalSplit';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { KEYBOARD_TYPE } from '@/Models/Constants';

const GestureKeyboard = () => {
  const {
    user: {
      settings: { selectedKeyboard },
    },
  } = useUserSessionStore();

  // console.log('[STATUS] keyboardType', keyboardType);

  // console.log('[STATUS] keyboardType', keyboardType);

  if (selectedKeyboard === KEYBOARD_TYPE.HORIZONTAL_TRACKING_MOUSE) {
    return <KeyboardWithTracking />;
  }

  if (selectedKeyboard === KEYBOARD_TYPE.VERTICAL_SPLIT_SELECTION) {
    return <KeyboardVerticalSplit />;
  }

  if (selectedKeyboard === KEYBOARD_TYPE.HORIZONTAL_SPLIT_SELECTION) {
    return <KeyboardHorizontalSplit />;
  }
  return <KeyboardHorizontalSplit />;
};

export default GestureKeyboard;
