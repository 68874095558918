import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { login } from '../../Services/AuthService';
import logo from '../../Assets/images/logo.svg';
import useUserSessionStore from '@/Models/useUserSessionStore';
import Preloader from '@/Components/Common/Preloader';
import { LoginContainer, LogoWrapper, Logo, FormContainer, Form, FormGroup, Label, Field, Button, Alert } from './Login.styles';

const Login: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const { setUserSessionState } = useUserSessionStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [isLandscape, setIsLandscape] = useState<boolean>(window.innerWidth > window.innerHeight);
  const [showPreloader, setShowPreloader] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('This field is required!').email('Invalid email address'),
    password: Yup.string().required('This field is required!'),
  });

  const handleLogin = async (formValue: { email: string; password: string }) => {
    const { email, password } = formValue;

    setMessage('');
    setLoading(true);
    setShowPreloader(true);

    try {
      await login(email, password);

      navigate('/home');
    } catch (error: unknown) {
      const resMessage = error instanceof Error ? error.message : (error as { response?: { data?: { message?: string } } })?.response?.data?.message || 'An unknown error occurred';

      setMessage(resMessage);
      setShowPreloader(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showPreloader && <Preloader message="Logging in..." />}
      <LoginContainer $isLandscape={isLandscape}>
        <LogoWrapper>
          <Logo alt="logo" src={logo} />
        </LogoWrapper>

        <FormContainer>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleLogin}>
            <Form>
              <FormGroup>
                <Label htmlFor="email">Email</Label>
                <Field name="email" type="text" />
                <ErrorMessage name="email" component={Alert} />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="password">Password</Label>
                <Field name="password" type="password" />
                <ErrorMessage name="password" component={Alert} />
              </FormGroup>

              <FormGroup>
                <Button type="submit" disabled={loading}>
                  {loading && <span className="spinner-border spinner-border-sm"></span>}
                  <span>Login</span>
                </Button>
              </FormGroup>

              {message && (
                <FormGroup>
                  <Alert role="alert">{message}</Alert>
                </FormGroup>
              )}
            </Form>
          </Formik>
        </FormContainer>
      </LoginContainer>
    </>
  );
};

export default Login;
