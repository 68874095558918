import { KEYBOARD_TYPE } from '@/Models/Constants';
import styled, { css } from 'styled-components';
import { device } from '@/Layout/breakpoints';

const { VERTICAL_SPLIT_SELECTION } = KEYBOARD_TYPE;

interface SpeakWrapperProps {
  $running?: boolean;
  $keyboardType: KEYBOARD_TYPE;
  $isVisible?: boolean;
}

export const SpeakWrapper = styled.div<SpeakWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  outline: 0.125rem solid rgba(255, 255, 255, 1);
  border-radius: 5px;
  padding: 2vh 2vw;
  opacity: 0.3;
  width: auto;
  min-height: 60px;
  transition: all 0.3s ease;

  ${props =>
    props.$keyboardType === VERTICAL_SPLIT_SELECTION &&
    css`
      padding: 0;
      outline: none;
    `}

  &:hover {
    outline: 4px solid rgba(255, 255, 255, 0.739);
    background: rgba(255, 255, 255, 0.1);
  }

  ${props =>
    props.$running &&
    css`
      opacity: 1;
    `}

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      opacity: 1;
    `}

  ${device.mobileAll} {
    justify-content: flex-start;
    outline: none;
    padding: 1vh;
    margin: 0;
    gap: 1vw;
  }
`;

export const SpeakText = styled.span<{ $keyboardType: KEYBOARD_TYPE; $isVisible?: boolean }>`
  color: white;
  font-size: clamp(0.8rem, 1.5vw, 1.25rem);
  font-weight: bold;
  margin-left: 1vw;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props =>
    props.$keyboardType === VERTICAL_SPLIT_SELECTION &&
    css`
      display: none;
    `}

  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      display: none;
    `}

  ${device.mobileAll} {
    display: none;
  }
`;

export const GestureFlagWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: clamp(48px, 8vw, 80px);
    height: auto;

    @media (max-height: 600px) {
      width: clamp(40px, 5vw, 60px);
    }
  }
`;

export const DebugInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1vw;
  color: white;
`;
