import styled from 'styled-components';
import { Form as FormikForm, Field as FormikField } from 'formik';

export const LoginContainer = styled.div<{ $isLandscape: boolean }>`
  display: flex;
  flex-direction: ${props => (props.$isLandscape ? 'row' : 'column')};
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const LogoWrapper = styled.div`
  background-color: var(--dark-text-color);
  border-radius: 100%;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
  padding: 1.5em;
  position: relative;
`;

export const Logo = styled.img`
  width: 200px;
  height: 200px;
`;

export const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin: 20px 45px;
  justify-content: space-around;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
`;

export const Form = styled(FormikForm)``;

export const FormGroup = styled.div`
  margin-top: 45px;
`;

export const Label = styled.label`
  display: block;
  font-weight: bolder;
  padding-bottom: 10px;
  font-size: 25px;
  color: white;
  letter-spacing: 1px;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
`;

export const Field = styled(FormikField)`
  min-width: 40vw;
  padding: 15px 15px;
  border-radius: 5px;
  font-size: 30px;
  margin-top: 10px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
`;

export const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const Alert = styled.div`
  font-size: 20px;
  margin-top: 15px;
  color: white;
  background-color: rgba(255, 0, 0, 0.843);
  padding: 15px 0 15px 15px;
  border-radius: 5px;
  font-weight: 600;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
`;
