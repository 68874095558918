import React from 'react';
import { COMMANDS, KEY_LIST, LEFT_COMMANDS, RIGHT_COMMANDS } from '@/Models/Constants';
import useGestureKeyboardStore from '@/Models/useGestureKeyboardStore';
import useTextToSpeech from '@/Services/useTextToSpeech';
import PhraseContainer from '@/Components/PhraseContainer/PhraseContainer';
import { KeyboardKey } from '../components/KeyboardKey/KeyboardKey';
import { KeyboardContainer, KeyboardContent, KeyboardKeys, CommandsRow } from './KeyboardWithTracking.styles';

export const KeyboardWithTracking: React.FC = () => {
  const { pickKey, phrase } = useGestureKeyboardStore();
  const { handleSynthesis } = useTextToSpeech();

  const handleClick = (key: string) => {
    if (key === COMMANDS.SPEAK) {
      handleSynthesis(phrase);
    } else {
      pickKey(key);
    }
  };

  return (
    <KeyboardContainer id="tracking-keyboard">
      <PhraseContainer />
      <KeyboardContent>
        <KeyboardKeys>
          {KEY_LIST.map(key => (
            <KeyboardKey key={key} value={key} onClick={() => handleClick(key)} />
          ))}
        </KeyboardKeys>
        <CommandsRow>
          {LEFT_COMMANDS.map(command => (
            <KeyboardKey key={command} value={command} onClick={() => handleClick(command)} />
          ))}
          {RIGHT_COMMANDS.map(command => (
            <KeyboardKey key={command} value={command} onClick={() => handleClick(command)} />
          ))}
        </CommandsRow>
      </KeyboardContent>
    </KeyboardContainer>
  );
};
