import { useRef, useEffect, useCallback } from 'react';
import { FaceLandmarker, FilesetResolver } from '@mediapipe/tasks-vision';
import useDetectionStore from '@/Models/useDetectionStore';
import useUserSessionStore from '@/Models/useUserSessionStore';
import { KEYBOARD_TYPE } from '@/Models/Constants';
import { IUserSessionState } from '@/Models/useUserSessionStore';
import { IDetectionStore } from '@/Models/useDetectionStore';

const { VERTICAL_SPLIT_SELECTION, HORIZONTAL_SPLIT_SELECTION } = KEYBOARD_TYPE;

const useFaceLandMarker = () => {
  const updateCountRef = useRef(0);

  const detector = useDetectionStore((state: IDetectionStore) => state.faceLandMark.detector);
  const videoElement = useDetectionStore((state: IDetectionStore) => state.webcam.videoElement);
  const setFaceLandMarkerDetector = useDetectionStore((state: IDetectionStore) => state.setFaceLandMarkerDetector);
  const setFaceLandMarkerDetectorResults = useDetectionStore((state: IDetectionStore) => state.setFaceLandMarkerDetectorResults);
  const setBenchmark = useDetectionStore((state: IDetectionStore) => state.setBenchmark);

  const selectedKeyboard = useUserSessionStore((state: IUserSessionState) => state.user.settings.selectedKeyboard);
  const isAuthenticated = useUserSessionStore((state: IUserSessionState) => state.isAuthenticated);

  const detectorRef = useRef<FaceLandmarker | null>(null);
  const lastVideoTimeRef = useRef<number>(0);
  const animationFrameRef = useRef<number | null>(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBenchmark({ PPS: updateCountRef.current });
      updateCountRef.current = 0;
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Cleanup function for animation frame
  const cleanupAnimationFrame = useCallback(() => {
    if (animationFrameRef.current) {
      window.cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
    }
  }, []);

  // Load model function
  const loadModel = async () => {
    try {
      if (!detectorRef.current) {
        const vision = await FilesetResolver.forVisionTasks('https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm');

        detectorRef.current = await FaceLandmarker.createFromOptions(vision, {
          baseOptions: {
            modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
            delegate: 'GPU',
          },
          outputFaceBlendshapes: true,
          outputFacialTransformationMatrixes: true,
          runningMode: 'VIDEO',
          numFaces: 1,
        });

        setFaceLandMarkerDetector(detectorRef.current);
      } else {
        console.log(' [APP INFO] Detector already loaded');
      }
    } catch (error) {
      console.error('[APP ERROR] Failed to load model:', error);
    }
  };

  // Predict function
  const predict = useCallback(() => {
    if (!isAuthenticated) {
      cleanupAnimationFrame();
      return;
    }

    try {
      const startTimeMs = performance.now();
      if (detector && videoElement && lastVideoTimeRef.current !== videoElement.currentTime) {
        lastVideoTimeRef.current = videoElement.currentTime;
        const results = detector.detectForVideo(videoElement, startTimeMs);
        setFaceLandMarkerDetectorResults(results);
        updateCountRef.current++;
      }
    } catch (error) {
      console.error('[ERROR] Face detection failed:', error);
      cleanupAnimationFrame();
      return;
    }

    if (isAuthenticated && [VERTICAL_SPLIT_SELECTION, HORIZONTAL_SPLIT_SELECTION].includes(selectedKeyboard)) {
      animationFrameRef.current = requestAnimationFrame(predict);
    }
  }, [videoElement, detector, selectedKeyboard, isAuthenticated, setFaceLandMarkerDetectorResults]);

  // Load model on mount
  useEffect(() => {
    loadModel();
    // console.log(' [APP INFO] Loading model');
  }, []);

  // Start prediction when everything is ready
  useEffect(() => {
    // console.log(' [APP INFO] Starting prediction');
    // const { video, detector } = faceLandMark;
    if (videoElement && detector && isAuthenticated && [VERTICAL_SPLIT_SELECTION, HORIZONTAL_SPLIT_SELECTION].includes(selectedKeyboard)) {
      predict();
    }

    return () => cleanupAnimationFrame();
  }, [videoElement, detector, isAuthenticated, selectedKeyboard, predict]);

  return {};
};

export default useFaceLandMarker;
