// Common device dimensions (width x height)
// Mobile Portrait:
// - iPhone 12/13/14: 390 x 844
// - iPhone 12/13/14 Pro Max: 428 x 926
// - Samsung Galaxy S21/S22: 360 x 800
// - Google Pixel 6/7: 393 x 850

// Tablet Portrait:
// - iPad Mini: 768 x 1024
// - iPad Air/Pro 11": 820 x 1180
// - iPad Pro 12.9": 1024 x 1366
// - Samsung Galaxy Tab S7/S8: 1200 x 1600

export const BREAKPOINTS = {
  // Mobile breakpoints
  MOBILE_MIN_WIDTH: 360, // Smallest common mobile width
  MOBILE_MAX_WIDTH: 428, // Largest mobile width (iPhone Pro Max)
  MOBILE_MIN_HEIGHT: 640, // Minimum height for modern phones
  MOBILE_MAX_HEIGHT: 926, // Maximum height (iPhone Pro Max)

  // Tablet breakpoints
  TABLET_MIN_WIDTH: 768, // iPad Mini width (smallest tablet)
  TABLET_MAX_WIDTH: 1200, // Galaxy Tab S7/S8
  TABLET_MIN_HEIGHT: 1024, // iPad Mini height
  TABLET_MAX_HEIGHT: 1600, // Maximum tablet height

  // Desktop
  DESKTOP_MIN: 1366,

  // Special cases
  MOBILE_LANDSCAPE_MAX_HEIGHT: 500, // Max height to consider a landscape phone
} as const;

// Media queries for different devices and orientations
export const device = {
  // Mobile portrait - specific for phones in portrait
  mobilePortrait: `@media 
    (min-width: ${BREAKPOINTS.MOBILE_MIN_WIDTH}px) and 
    (max-width: ${BREAKPOINTS.MOBILE_MAX_WIDTH}px) and 
    (min-height: ${BREAKPOINTS.MOBILE_MIN_HEIGHT}px) and 
    (orientation: portrait)`,

  // Mobile landscape - specific for phones in landscape
  mobileLandscape: `@media 
    (min-height: ${BREAKPOINTS.MOBILE_MIN_WIDTH}px) and 
    (max-height: ${BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT}px) and 
    (orientation: landscape)`,

  // Combined mobile query for both orientations
  mobileAll: `@media 
    ((min-width: ${BREAKPOINTS.MOBILE_MIN_WIDTH}px) and 
     (max-width: ${BREAKPOINTS.MOBILE_MAX_WIDTH}px) and 
     (min-height: ${BREAKPOINTS.MOBILE_MIN_HEIGHT}px) and 
     (orientation: portrait))
    ,
    ((min-height: ${BREAKPOINTS.MOBILE_MIN_WIDTH}px) and 
     (max-height: ${BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT}px) and 
     (orientation: landscape))`,

  // Tablet portrait - specific for tablets in portrait
  tabletPortrait: `@media 
    (min-width: ${BREAKPOINTS.TABLET_MIN_WIDTH}px) and 
    (max-width: ${BREAKPOINTS.TABLET_MAX_WIDTH}px) and 
    (min-height: ${BREAKPOINTS.TABLET_MIN_HEIGHT}px) and 
    (orientation: portrait)`,

  // Tablet landscape - specific for tablets in landscape
  tabletLandscape: `@media 
    (min-width: ${BREAKPOINTS.TABLET_MIN_HEIGHT}px) and 
    (max-width: ${BREAKPOINTS.TABLET_MAX_HEIGHT}px) and 
    (orientation: landscape)`,

  // Desktop devices
  desktop: `@media (min-width: ${BREAKPOINTS.DESKTOP_MIN}px)`,

  // General orientation queries (use with caution, prefer device-specific orientations)
  landscape: `@media (orientation: landscape)`,
  portrait: `@media (orientation: portrait)`,

  // Desktop landscape (specific case)
  desktopLandscape: `@media (min-width: ${BREAKPOINTS.DESKTOP_MIN}px) and (orientation: landscape)`,
} as const;
